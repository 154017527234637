import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../components/common/Text/Text';
import { setMenu } from '../../features/menu/menuSlice';
import { AppDispatch, RootState } from '../../store';
import css from './AIHumanPanel.module.scss';
import TextField from '../../components/common/TextField/TextField';
import DatePicker from '../../components/common/DatePicker/DatePicker';
import Divider from '../../components/common/Divider/Divider';
import Tabs from '../../components/common/Tabs/Tabs';
import ProgressButton from '../../components/common/ProgressButton/ProgressButton';
import ReporterSelection from '../common/ReporterSelection';
import ReporterResize from './ReporterResize';
import ArticleField from './ArticleField';
import StepItem from '../../components/common/StepItem/StepItem';
import Icon from '../../components/common/Icon/Icon';
import classNames from 'classnames';
import Completed from '../common/Completed';
import { articleType, createVideo, reset, setArticle, setDate, setEpisode, setReporter, setResize, setTitle, resetVideoStatus, getVideoStatus, resetArticleStatus, createArticle, getArticleStatus, setArticleStatus, setVideoStatus } from '../../features/human/humanSlice';
import { isEmptyObject, isEmptyPosition } from '../../utils';
import InProgress from '../common/InProgress';
import AudioEdit from './AudioEdit';
import { reporterType } from '../../features/common/commonSlice';
import { abortAllRequests } from '../../utils/api';
import Spinner from '../../components/common/Spinner/Spinner';

const AIHumanPanel = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {userId} = useSelector((state: RootState) => state.user);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const { bTitle, bDate, bEpisode, selectedReporter, resize, article, ttsId, resourceId, dataInfo, ttsInfo, loading } = useSelector((state: RootState) => state.human);
    const [activeTab, setTab] = useState<string>('video');
    const [enable, setEnable] = useState<boolean>(false);
    const [step, setStep] = useState<'reporter' | 'resize' | 'article' | 'audioEdit' | 'completed'>('reporter');
    const [progress, setProgress] = useState<number>(0);
    const [creating, setCreating] = useState<boolean>(false);

    useEffect(() => {
        dispatch(reset());
        dispatch(setMenu('ai-human'));

        return () => {
            cancelCreating();
            dispatch(reset());
        }
    }, [dispatch]);

    useEffect(() => {
        // TODO: store의 reporter 정보 삭제
        setStep('reporter');
        dispatch(reset());
    }, [dispatch, activeTab]);

    const cancelCreating = () => {
        abortAllRequests();
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        setCreating(false);
    };

    const checkCreateVideoStatus = useCallback(async (resourceId: string) => {
        try {
            const response = await getVideoStatus({resourceId, userId});
            const {dataInfo, errMsg, progress, status} = response;

            setProgress(progress);
            if (status === 'succeeded') {
                setCreating(false);
                setProgress(-1);
                setEnable(false);
                dispatch(setVideoStatus({
                    ...dataInfo,
                    script: [{order: 1, headline: '', summary: article.text}]
                }));
                setStep('completed');
            } else if (status === 'failed') {
                cancelCreating();
            } else if (status !== 'succeeded') {
                timeoutRef.current = setTimeout(() => checkCreateVideoStatus(resourceId), 500); // 2초 후에 다시 확인
            } else {
                console.error('Error checking upload status:', errMsg);
                cancelCreating();
            }
        } catch (error) {
            console.error('Error checking create status: ', error);
            setStep('audioEdit');
            cancelCreating();
        }
    }, [dispatch, userId, article.text]);

    useEffect(() => {
        if (resourceId) {
            setCreating(true);
            checkCreateVideoStatus(resourceId);
        }
    }, [resourceId, checkCreateVideoStatus]);

    const handleCreate = () => {
        if (activeTab === 'video') {
            dispatch(resetVideoStatus());
            dispatch(createVideo());
        } else {
            handleCreateArticle();
        }
    };

    const checkCreateArticleStatus = useCallback(async (ttsId: string) => {
        try {
            const response = await getArticleStatus({ttsId, userId});
            const {ttsInfo, errMsg, progress, status} = response;

            setProgress(progress);
            if (status === 'succeeded') {
                setCreating(false);
                setProgress(-1);

                dispatch(setArticleStatus(ttsInfo));
                setStep('audioEdit');
            } else if (status === 'failed') {
                cancelCreating();
            } else if (status !== 'succeeded') {
                setTimeout(() => checkCreateArticleStatus(ttsId), 500); // 2초 후에 다시 확인
            } else {
                console.error('Error checking upload status:', errMsg);
                cancelCreating();
            }
        } catch (error) {
            console.error('Error checking create status: ', error);
            setStep('audioEdit');
            cancelCreating();
        }
    }, [dispatch, userId]);

    useEffect(() => {
        if (ttsId) {
            setCreating(true);
            checkCreateArticleStatus(ttsId);
        }
    }, [ttsId, checkCreateArticleStatus]);

    const handleCreateArticle = () => {
        //dispatch(resetArticleStatus());
        dispatch(createArticle());
    };

    const handleApplyChangedArticle = (text: string) => {
        dispatch(resetArticleStatus());
        dispatch(setArticle({
            text,
            textLength: text.length
        }));
        dispatch(createArticle());
    };

    const handleCompleteReporter = (ev: reporterType) => {
        dispatch(setReporter(ev));
        if (activeTab === 'video') {
            setStep('resize');
        } else {
            setStep('article');
        }
    };

    const handleCompleteResize = (ev: {width: number; height: number; wPercent: number; hPercent: number; x: number; y: number; xPercent: number; yPercent: number; flip: boolean;}) => {
        if (!isEmptyPosition(ev)) {
            dispatch(setResize(ev));
            setStep('article');
        }
    };

    const handleChangeArticle = (ev: articleType) => {
        if (ev.text) {
            dispatch(setArticle(ev));
        } else {
            dispatch(setArticle({
                text: '',
                textLength: 0
            }));
        }
    };

    useEffect(() => {
        const isVideoType = activeTab === 'video';
        const hasRequiredFields = bDate && !isEmptyObject(selectedReporter);
        const isFormValid = hasRequiredFields && (isVideoType ? !isEmptyPosition(resize) && ttsInfo.src : article.text);
        setEnable(!!isFormValid);
    }, [activeTab, bDate, selectedReporter, resize, article, ttsInfo]);

    const handleChangeDate = (ev: any) => {
        dispatch(setDate(ev.toISOString()));
    };

    return (
        <div className={css.viewerPanel}>
            <div className={css.info}>
                <Text>{'타이틀'}</Text>
                <TextField
                    disabled={creating}
                    onChange={(ev: any) => dispatch(setTitle(ev.target.value))}
                    value={bTitle}
                />
                <div className={css.columns}>
                    <span>
                        <Text>{'방송 일자'}</Text>
                        <DatePicker
                            date={bDate ? new Date(bDate) : new Date()}
                            onChange={handleChangeDate}
                            disabled={creating}
                        />
                    </span>
                    <span>
                        <Text>{'방송 회차'}</Text>
                        <TextField
                            type="number"
                            disabled={creating}
                            onChange={(ev: any) => dispatch(setEpisode(ev.target.value))}
                            value={bEpisode}
                        />
                    </span>
                </div>
                <Divider />
                <Tabs
                    options={[
                        {value: 'video', label: 'AI 기자 + 음성'},
                        {value: 'audio', label: '음성만 제작'}
                    ]}
                    onSelect={(selected: string) => setTab(selected)}
                />
                <div className={css.steps}>
                    <StepItem
                        title="AI 기자"
                        icon="human"
                        guidanceStr="원하는 AI 기자를 선택해 주세요."
                        selected={!creating && step === 'reporter'}
                        disabled={creating}
                        selectedValues={selectedReporter.name && selectedReporter.speed ? [
                            selectedReporter.name,
                            `${selectedReporter.speed} 배속`
                        ] : undefined}
                        imgSrc={selectedReporter.profileUrl}
                        onClick={() => setStep('reporter')}
                    />
                    <Icon className={classNames(css.stepArrowIcon, {[css.active]: (step !== 'completed')})} size="tiny">{'downTriangle'}</Icon>
                    {
                        activeTab === 'video' ?
                            <>
                                <StepItem
                                    title="위치 / 크기 조정"
                                    icon="resize"
                                    guidanceStr="위치와 크기를 조정 해주세요."
                                    selected={!creating && step === 'resize'}
                                    disabled={creating || (step !== 'resize' && (!selectedReporter.fullProfileUrl && !isEmptyPosition(resize)))}
                                    imgSrc={selectedReporter.fullProfileUrl}
                                    position={!isEmptyPosition(resize) ? resize : null}
                                    onClick={() => setStep('resize')}
                                />
                                <Icon className={classNames(css.stepArrowIcon, {[css.active]: (step === 'resize' || step === 'article' || step === 'audioEdit')})} size="tiny">{'downTriangle'}</Icon>
                            </> : null
                    }
                    <StepItem
                        title="기사 입력"
                        icon="articleInput"
                        guidanceStr="기사 내용을 입력해 주세요."
                        selected={!creating && step === 'article'}
                        disabled={creating || (step !== 'article' && step !== 'audioEdit')}
                        selectedValues={article.text ? [
                            `글자 수 : ${article.textLength}자`,
                            `예상 러닝타임 : ${Math.ceil(article.textLength * 0.15)}초`
                        ] : undefined}
                        onClick={() => setStep('article')}
                    />
                </div>

                <div className={css.buttons}>
                    {creating ?
                        <div className={css.creatingNotice}>{'화면을 벗어나면 작업 내용이 모두 사라집니다.'}</div> : null
                    }
                    <ProgressButton
                        className={css.createBtn}
                        disabled={!enable}
                        progress={creating ? progress : null}
                        onClick={handleCreate}
                    >
                        {creating ? '동영상 생성중...' : step === 'completed' ? '동영상 생성 완료' : '동영상 생성'}
                    </ProgressButton>
                </div>
            </div>
            <div className={css.container}>
                {step === 'reporter' ?
                    <ReporterSelection
                        reporterSpeed={selectedReporter.speed}
                        onComplete={handleCompleteReporter}
                    /> :
                    step === 'resize' ?
                        <ReporterResize onComplete={handleCompleteResize} /> :
                        step === 'article' ?
                            <ArticleField
                                type={activeTab}
                                onChange={handleChangeArticle}
                                onRequest={handleCreateArticle}
                            /> :
                            creating ?
                                <InProgress
                                    content={`${creating ? 'AI 동영상' : 'AI 음성'}을 제작 중입니다. 잠시만 기다려주세요.`}
                                /> :
                                step === 'audioEdit' ?
                                    <AudioEdit type={activeTab} onApply={handleApplyChangedArticle} /> :
                                    <Completed type={activeTab} dataInfo={dataInfo} />
                }
            </div>
            {loading && <Spinner blockOnClick scrim />}
        </div>
    );
}

export default AIHumanPanel;
