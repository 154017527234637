import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/common/Button/Button';
import TextField from '../../components/common/TextField/TextField';
import { articleType, setRunningTime } from '../../features/human/humanSlice';
import { AppDispatch, RootState } from '../../store';
import css from './ArticleField.module.scss'


const ArticleField = ({
    type,
    onChange,
    onRequest
}: {
    type?: string;
    onChange?: (ev: articleType) => void;
    onRequest?: () => void;
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { selectedReporter, article } = useSelector((state: RootState) => state.human);

    const handleChange = (ev: any) => {
        onChange && onChange({
            text: ev.target.value,
            textLength: ev.target.value.length
        });
    };

    return (
        <div className={css.viewer}>
            {type === 'video' ?
                <div className={css.top}>
                    <Button marked onClick={onRequest} disabled={article.text?.length < 20}>{'기사 입력 완료'}</Button>
                </div> : null
            }
            <div className={css.articleField}>
                <TextField
                    multiLine
                    className={css.article}
                    placeholder="기사 내용을 입력해 주세요. (최소 20자)"
                    value={article.text}
                    onChange={handleChange}
                />
            </div>
        </div>
    );
};

export default ArticleField;
