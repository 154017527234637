import css from './NoData.module.scss'


const NoData = () => {
    return (
        <div className={css.noData}>{'저장된 켄텐츠가 없습니다.'}</div>
    );
};

export default NoData;
