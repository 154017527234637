import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/common/Button/Button';
import Icon from '../../components/common/Icon/Icon';
import Text from '../../components/common/Text/Text';
import SheetTab from '../../components/common/SheetTab/SheetTab';
import { AppDispatch, RootState } from '../../store';
import css from './AIArticle.module.scss'
import Uploader from '../../components/common/Uploader/Uploader';
import TextField from '../../components/common/TextField/TextField';
import { addSheet, createSummary, resetSummary, setAiArticle, setDuration, setError, setResource, setWithFootage } from '../../features/news/newsSlice';
import Popup from '../../components/common/Popup/Popup';
import ContentEditable from 'react-contenteditable';
import Dropdown from '../../components/common/Dropdown/Dropdown';
import Spinner from '../../components/common/Spinner/Spinner';

const AIArticle = ({
    withHuman,
    onComplete
}: {
    withHuman?: boolean;
    onComplete: () => void;
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { aiArticle, validAiArticleCnt, loading, error } = useSelector((state: RootState) => state.news);
    const [selectedTabIdx, setSelectedTabIdx] = useState<number>(0);
    const [isOpenRemoveModal, setRemoveModal] = useState<boolean>(false);
    const [isOpenDurModal, setDurModal] = useState<boolean>(false);
    const [idxToRemove, setIdxToRemove] = useState<number | null>(null);
    const defaultDur = aiArticle[selectedTabIdx]?.articleTime;
    const [selectedDur, setDur] = useState<number>(defaultDur);
    const [enableSummary, setEnableSummary] = useState<boolean>(false);
    const [validCntMsg, setValidCntMsg] = useState<string>('');
    const [uploading, setUploading] = useState<boolean>(false);
    const [openUploadNoticePopup, setUploadNoticePopup] = useState<boolean>(false);
    const [openInitNoticePopup, setInitNoticePopup] = useState<boolean>(false);
    const [checkedInitNotice, setCheckedInitNotice] = useState<boolean>(true);

    const hasSummary = !!aiArticle[selectedTabIdx]?.aiSummary.summary;

    useEffect(() => {
        setEnableSummary(aiArticle[selectedTabIdx]?.articleText.length > 20);
    }, [uploading, aiArticle[selectedTabIdx]?.articleText]);

    useEffect(() => {
        const dur = aiArticle[selectedTabIdx]?.articleTime;
        setDur(dur);

        if (aiArticle.length <= selectedTabIdx) {
            setSelectedTabIdx(aiArticle.length - 1);
        }

        //const hasSummary = !!aiArticle[selectedTabIdx]?.aiSummary.summary;
        if (!hasSummary) {
            setCheckedInitNotice(true);
        }
    }, [aiArticle, selectedTabIdx]);

    const handleChangeInput = useCallback((ev: any) => {
        if (hasSummary && !checkedInitNotice) {
            // 기사 변경 시, 기존 요약 내용 초기화 된다는 경고 팝업 확인하지 않은 경우, 팝업 출력
            setInitNoticePopup(true);
            return;
        }

        const _data = [...aiArticle].map((obj, index) => {
            if (index === selectedTabIdx) {
                return {
                    ...obj,
                    articleText: ev.target.value
                }
            } else {
                return obj;
            }
        })

        dispatch(setAiArticle(_data));
    }, [dispatch, selectedTabIdx, aiArticle, checkedInitNotice]);

    const summarize = () => {
        // TODO: 정리된 이 후, 로직 최적화 필요.
        //const hasSummary = !!aiArticle[selectedTabIdx]?.aiSummary.summary;
        if (hasSummary) {
            // 다시 요약하기

        } else {
            // 최초 요약하기
            if (withHuman) {
                // 음성 포함
                if (aiArticle[selectedTabIdx].videoId) {
                    // 비디오 첨부 있는 경우 - OK
                } else {
                    // 비디오 첨부 없는 경우
                    setUploadNoticePopup(true);
                    return;
                }
            } else {
                // 음성만 - OK
            }
        }


        if (!aiArticle[selectedTabIdx].videoId) {
            // 자료영상 없는 경우
            setDurModal(true);
        } else {
            // 자료영상 있는 경우
            dispatch(createSummary({
                index: selectedTabIdx
            }));
            setCheckedInitNotice(false);    // 요약하기 실행 후, 변경 시 초기화 경고 팝업 출력 필요함.
        }
    };

    const summaryWithDur = useCallback(() => {
        setDurModal(false);
        dispatch(createSummary({
            index: selectedTabIdx
        }));
        setCheckedInitNotice(false);    // 요약하기 실행 후, 변경 시 초기화 경고 팝업 출력 필요함.
    }, [dispatch, selectedTabIdx]);

    const addNewSheet = () => {
        dispatch(addSheet());
        setSelectedTabIdx(selectedTabIdx + 1);
    };

    const removeSheet = useCallback(() => {
        setRemoveModal(false);
        if (typeof idxToRemove === 'number' && idxToRemove > -1) {
            const _data = aiArticle.slice();
            _data.splice(idxToRemove, 1);
            dispatch(setAiArticle(_data));
        }
    }, [idxToRemove, aiArticle, dispatch]);

    const handleRemove = (idx: number) => {
        setIdxToRemove(idx);
        setRemoveModal(true);
    };

    const cancelRemove = () => {
        setIdxToRemove(null);
        setRemoveModal(false);
    }

    const nextSheet = useCallback(() => {
        if (selectedTabIdx === 2 && aiArticle.length === 3) {
            addNewSheet();
        } else {
            setSelectedTabIdx(selectedTabIdx + 1);
        }
    }, [selectedTabIdx, aiArticle]);

    //const extractHeadlineAndSummary = (text: string) => {
    //    // h1 태그 사이의 문자열 추출
    //    const headlineMatch = text.match(/<h1>(.*?)<\/h1>/);
    //    const headline = headlineMatch ? headlineMatch[1] : '';

    //    // <h1> 태그 이후의 모든 문자열 추출
    //    const summaryMatch = text.split(/<\/h1>/)[1];
    //    const summary = summaryMatch ? summaryMatch.trim() : '';

    //    return {
    //        headline,
    //        summary
    //    };
    //};

    const handleChangeHeadline = (ev: any) => {
        if (!ev.target.value) return;

        const headline = ev.target.value.trim();
        const _data = [...aiArticle].map((obj, index) => {
            if (index === selectedTabIdx) {
                return {
                    ...obj,
                    aiSummary: {
                        ...obj.aiSummary,
                        headline
                    }
                }
            } else {
                return obj;
            }
        });

        dispatch(setAiArticle(_data));
    };

    const handleChangeBody = (ev: any) => {
        if (!ev.target.value) return;

        const summary = ev.target.value.trim();
        const _data = [...aiArticle].map((obj, index) => {
            if (index === selectedTabIdx) {
                return {
                    ...obj,
                    aiSummary: {
                        ...obj.aiSummary,
                        summary
                    }
                }
            } else {
                return obj;
            }
        });

        dispatch(setAiArticle(_data));
    };

    const handleSelectDur = useCallback((ev: any) => {
        dispatch(setDuration({index: selectedTabIdx, value: ev.id}));
    }, [dispatch, selectedTabIdx]);

    const handleSetResource = useCallback((ev: {
        resourceId: string;
        thumbnailUrl: string;
        src: string;
        filename: string;
    }) => {
        dispatch(setResource({index: selectedTabIdx, data: {...ev}}));  // FIXME: 경고 팝업 이후 실행할 것.
        setEnableSummary(aiArticle[selectedTabIdx]?.articleText.length > 20);
        setUploading(false);
    }, [dispatch, selectedTabIdx, aiArticle]);

    const handleDelete = useCallback(() => {
        dispatch(setResource({
            index: selectedTabIdx,
            data: {
                resourceId: '',
                thumbnailUrl: '',
                src: '',
                filename: ''
            }
        }));
    }, [dispatch, selectedTabIdx]);

    const handleCompleteArticle = useCallback(() => {

        const hasVideoIndexes = aiArticle
            .map((obj, index) => (obj.videoId ? index + 1 : null))
            .filter(index => index !== null);
        if (hasVideoIndexes.length > 0 && hasVideoIndexes.length !== validAiArticleCnt) {
            setValidCntMsg(`{${hasVideoIndexes.join(', ')}}번 기사에만 동영상 자료가 포함되어 있습니다.\n나머지 기사에도 동영상을 넣어주세요.`);
        } else if (hasVideoIndexes.length > 0) {
            // with videos
            dispatch(setWithFootage(true));
            onComplete();
        } else {
            // without videos
            if (withHuman) {
                setValidCntMsg(`모든 기사에 동영상 자료가 포함되어 있지 않습니다.\n동영상을 넣어주세요.`);
            } else {
                dispatch(setWithFootage(false));
                onComplete();
            }
        }
    }, [dispatch, onComplete, aiArticle, validAiArticleCnt, withHuman]);

    const confirmChangeArticle = useCallback(() => {
        setCheckedInitNotice(true);
        setInitNoticePopup(false);
        dispatch(resetSummary({index: selectedTabIdx}));
    }, [dispatch, selectedTabIdx]);

    return (
        <div className={css.viewer}>
            <div className={css.top}>
                <Button
                    marked
                    icon="check"
                    disabled={validAiArticleCnt < 3}
                    onClick={handleCompleteArticle}
                >{'기사 입력 완료'}</Button>
                <div className={css.sheetTabs}>
                    <div className={css.tabs}>
                        {aiArticle.map((item, index) => {
                            return (
                                <SheetTab
                                    key={index}
                                    active={!!item.aiSummary.summary}
                                    selected={selectedTabIdx === index}
                                    disabled={(index > 0 && !aiArticle[index - 1]?.aiSummary?.summary)}
                                    blockOnClick={uploading}
                                    removable={validAiArticleCnt > 3 && !!item.aiSummary}
                                    onRemove={() => handleRemove(index)}
                                    onClick={() => setSelectedTabIdx(index)}
                                />
                            );
                        })}
                        {aiArticle.length < 4 ?
                            <Icon size="tiny" hoverable onClick={addNewSheet} disabled={uploading}>{'add'}</Icon> : null
                        }
                    </div>
                    <span>{'기사는 3개에서 4개까지 입력가능합니다.'}</span>
                </div>
            </div>
            <div className={css.contents}>
                <div className={css.inputs}>
                    <div className={css.uploadBox}>
                        <Text required={withHuman} desc="헤드라인에 사용할 자료 영상을 업로드 해주세요.">{'동영상 업로드'}</Text>
                        <Uploader
                            useCheckPopup={!!aiArticle[selectedTabIdx]?.resourceInfo.src}
                            type="video"
                            onComplete={handleSetResource}
                            data={aiArticle[selectedTabIdx]?.resourceInfo}
                            onUploadStart={() => setUploading(true)}
                            onUploadEnd={() => setUploading(false)}
                            onDelete={handleDelete}
                        />
                    </div>
                    <div className={css.articleBox}>
                        <Text required desc="기사 내용을 입력해 주세요.">{'기사 입력'}</Text>
                        <div className={css.inputBox}>
                            <TextField
                                multiLine
                                className={css.textfield}
                                onChange={handleChangeInput}
                                placeholder="기사 내용을 입력해 주세요. (최소 20자)"
                                value={aiArticle[selectedTabIdx]?.articleText}
                            />
                            <Button
                                className={css.aiButton}
                                disabled={uploading || !enableSummary}
                                onClick={summarize}
                                icon="aiStar"
                            >
                                {'AI 요약하기'}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={css.summaryBox}>
                    <Text>{'AI 요약 결과'}</Text>
                    <div className={css.summary}>
                        <ContentEditable
                            className={css.headline}
                            html={
                                `${aiArticle[selectedTabIdx]?.aiSummary.headline}`
                            }
                            disabled={!aiArticle[selectedTabIdx]?.aiSummary.headline}
                            onChange={handleChangeHeadline}
                        />
                        <ContentEditable
                            className={css.body}
                            html={
                                `${aiArticle[selectedTabIdx]?.aiSummary.summary}`
                            }
                            disabled={!aiArticle[selectedTabIdx]?.aiSummary.summary}
                            onChange={handleChangeBody}
                        />
                    </div>
                </div>
                <div className={css.bottomButtons}>
                    <Button
                        disabled={
                            uploading ||
                            aiArticle.length >= 4 ||
                            !hasSummary
                        }
                        onClick={nextSheet}
                    >
                        {'다음 기사 입력하기'}
                    </Button>
                </div>
            </div>
            <Popup
                open={isOpenRemoveModal}
                content={'선택한 기사를 삭제 하시겠습니까?'}
                onClose={() =>cancelRemove()}
                buttons={
                    <>
                        <Button type="line" onClick={cancelRemove}>{'취소'}</Button>
                        <Button onClick={removeSheet}>{'삭제'}</Button>
                    </>
                }
            />
            <Popup
                open={isOpenDurModal}
                content={'기사를 요약할 길이를 선택해 주세요.'}
                contentSlot={
                    <Dropdown
                        className={css.durDropdown}
                        options={[
                            {id: 20, label: '20초'},
                            {id: 25, label: '25초'},
                            {id: 30, label: '30초'}
                        ]}
                        selected={selectedDur}
                        onSelect={handleSelectDur}
                    />
                }
                onClose={() => setDurModal(false)}
                buttons={
                    <>
                        <Button type="line" onClick={() => setDurModal(false)}>{'취소'}</Button>
                        <Button onClick={summaryWithDur}>{'확인'}</Button>
                    </>
                }
            />
            <Popup
                open={!!validCntMsg}
                content={validCntMsg}
                onClose={() => setValidCntMsg('')}
                buttons={
                    <>
                        <Button onClick={() => setValidCntMsg('')}>{'확인'}</Button>
                    </>
                }
            />
            <Popup
                open={openUploadNoticePopup}
                content={'자료 영상은 필수 입력항목입니다.'}
                onClose={() => setUploadNoticePopup(false)}
                buttons={
                    <>
                        <Button onClick={() => setUploadNoticePopup(false)}>{'확인'}</Button>
                    </>
                }
            />
            <Popup
                open={openInitNoticePopup}
                content={'기사 변경 시, 기존 내용은 모두 초기화됩니다.\n변경 하시겠습니까?'}
                onClose={() => {
                    setInitNoticePopup(false)}
                }
                buttons={
                    <>
                        <Button type="line" onClick={() => {
                            setCheckedInitNotice(false);
                            setInitNoticePopup(false)
                        }}>{'취소'}</Button>
                        <Button onClick={confirmChangeArticle}>{'확인'}</Button>
                    </>
                }
            />
            <Popup
                open={error}
                content={'기사 요약에 실패했습니다. 다시 시도해 주세요.'}
                onClose={() => dispatch(setError(false))}
                buttons={
                    <>
                        <Button onClick={() => dispatch(setError(false))}>{'확인'}</Button>
                    </>
                }
            />
            {loading && <Spinner blockOnClick scrim />}
        </div>
    );
};

export default AIArticle;
