import { useCallback, useEffect, useState } from 'react';
import Button from '../../components/common/Button/Button';
import Divider from '../../components/common/Divider/Divider';
import Dropdown from '../../components/common/Dropdown/Dropdown';
import Tag from '../../components/common/Tag/Tag';
import TextField from '../../components/common/TextField/TextField';
import FilterPopup from './FilterPopup';
import css from './Dashboard.module.scss';
import ListView from './ListView';
import GridView from './GridView';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { searchResource } from '../../features/dashboard/dashboardSlice';
import { getTimeString } from '../../utils';

interface contentDataType {
    id: 'all' | 'image' | 'video';
    label: string;
}
const TYPE_OPTIONS: contentDataType[] = [
    {id: 'all', label: '전체'},
    {id: 'image', label: 'AI 이미지'},
    {id: 'video', label: 'AI 비디오'}
];

const Dashboard = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
    const [inputText, setInputText] = useState<string>('');
    const [keywords, setKeywords] = useState<string[]>([]);
    const [selectedType, setType] = useState<{id: 'all' | 'image' | 'video'; label: string;}>(TYPE_OPTIONS[0]);
    const [viewType, setViewType] = useState<'grid' | 'list'>('grid');
    const [tags, setTag] = useState<{type: 'keyword' | 'broadDate' | 'createdDate'; label: string; createdDateFrom?: string; createdDateTo?: string; broadDate?: string;}[]>([]);

    useEffect(() => {
        dispatch(searchResource({
            type: selectedType.id,
            createdDateFrom: tags.find(item => item.type === 'createdDate')?.createdDateFrom,
            createdDateTo: tags.find(item => item.type === 'createdDate')?.createdDateTo,
            broadDate: tags.find(item => item.type === 'broadDate')?.broadDate,
            keywords: tags.filter(tag => tag.type === 'keyword').map(item => item.label)
        }));
    }, [tags]);

    const handleApplyFilter = useCallback((ev: {
        startDate: string;
        endDate: string;
        broadDate: string;
    }) => {
        const _tags = tags.filter(item => (item.type !== 'createdDate' && item.type !== 'broadDate'));
        _tags.push({
            type: 'createdDate',
            label: `생성일자: ${getTimeString(ev.startDate, 'yyyy.MM.dd')}~${getTimeString(ev.endDate, 'yyyy.MM.dd')}`,
            createdDateFrom: ev.startDate,
            createdDateTo: ev.endDate
        });
        _tags.push({
            type: 'broadDate',
            label: `방송일자: ${getTimeString(ev.broadDate, 'yyyy.MM.dd')}`,
            broadDate: ev.broadDate
        });
        setTag(_tags);
    }, [tags]);

    const handleSelectType = (ev: contentDataType) => {
        setType(ev);
    };

    const removeTag = (idx: number) => {
        const updates = tags.filter((_, index) => index !== idx);
        setTag(updates);
    };

    const handleChangeSearch = (ev: any) => {
        setInputText(ev.target?.value.trim() || '');
    };

    const handleViewType = () => {
        if (viewType === 'grid') {
            setViewType('list');
        } else {
            setViewType('grid');
        }
    };

    const reset = () => {
        setType(TYPE_OPTIONS[0]);
        setTag([]);
    };

    const handleKeyUp = (ev: any) => {
        if (ev.code === 'Enter') {
            if (inputText && !keywords.includes(inputText)) {
                setKeywords([...keywords, inputText]);
                setTag([...tags, {type: 'keyword', label: inputText}]);
                setInputText(''); // 입력창 초기화
            }
        }
    };

    return (
        <>
            <div className={css.dashboard}>
                <div className={css.header}>
                    <div className={css.top}>
                        <Dropdown
                            className={css.typemenu}
                            options={TYPE_OPTIONS}
                            selected={selectedType.id}
                            onSelect={handleSelectType}
                        />
                        <div className={css.right}>
                            <Button icon={viewType} onClick={handleViewType} />
                            <TextField
                                className={css.search}
                                icon="search"
                                placeholder="ex. 뉴스 타이틀, 회차, 키워드"
                                value={inputText}
                                onKeyUp={handleKeyUp}
                                onChange={handleChangeSearch}
                            />
                            <Button icon="filter" onClick={() => setOpenFilter(true)} />
                        </div>
                    </div>
                    <div className={css.bottom}>
                        <div className={css.tags}>
                            {tags.map((f, idx) => {
                                return (
                                    <Tag key={idx} onRemove={() => removeTag(idx)}>{f.label}</Tag>
                                );
                            })}
                        </div>
                        <Button icon="reset" priority="secondary" type="line" inline onClick={reset}>{'초기화'}</Button>
                    </div>
                    <Divider />
                </div>
                {viewType === 'grid' ?
                    <GridView /> :
                    <ListView />
                }
            </div>
            <FilterPopup
                open={isOpenFilter}
                onClose={() => setOpenFilter(false)}
                onApply={handleApplyFilter}
            />
        </>
    );
}

export default Dashboard;
