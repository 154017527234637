import { forwardRef, useEffect, useRef, useState, useImperativeHandle } from 'react';
import { convertPxToNewBase, inrange } from '../../utils';
import registDragEvent from '../../utils/registDragEvent';
import css from './ImageResize.module.scss';
import classNames from 'classnames';
import Icon from '../common/Icon/Icon';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import trReporter from '../../assets/tr_reporter.png';

//const BOUNDARY_WIDTH = parseInt(rem(960).split('px')[0]);
//const BOUNDARY_HEIGHT = parseInt(rem(540).split('px')[0]);
const MIN_W = 80;
const MIN_H = 80;

const ImageResize = forwardRef(({
    onChange
}: {
    onChange?: (ev: {x: number; y: number; w: number; h: number; flip: boolean;}) => void;
}, ref) => {
    //const dispatch = useDispatch<AppDispatch>();
    const boxRef = useRef<HTMLDivElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const boundaryRef = useRef<HTMLDivElement>(null);
    const { resize, selectedReporter } = useSelector((state: RootState) => state.human);
    const [boxRatio, setBoxRatio] = useState(1);
    const [{ x, y, w, h }, setConfig] = useState({
        x: convertPxToNewBase(resize.x),
        y: convertPxToNewBase(resize.y),
        w: convertPxToNewBase(resize.width),
        h: convertPxToNewBase(resize.height)
    });
    const [originValues, setOriginValues] = useState({
        oWidth: convertPxToNewBase(resize.width),
        oHeight: convertPxToNewBase(resize.height),
        ratio: resize.height / resize.width
    });
    const [isFlip, setFlip] = useState(resize.flip);

    useEffect(() => {
        onChange && onChange({x, y, w, h, flip: isFlip});

        if (boxRef.current) {
            const box = boxRef.current.getBoundingClientRect();
            const v = (box.height / box.width);
            setBoxRatio(v);
        }
    }, [onChange, x, y, w, h, isFlip]);

    useImperativeHandle(ref, () => {
        return {
            getBounding: () => {
                return {x, y, w, h, flip: isFlip};
            },
            getCurrentValue: () => {
                if (!(boundaryRef.current && boxRef.current)) return;
                const boundary = boundaryRef.current.getBoundingClientRect();
                const ret = {
                    width: w,
                    height: h,
                    wPercent: w / originValues.oWidth * 100,
                    hPercent: h / originValues.oHeight * 100,
                    x: boxRef.current?.offsetLeft,
                    y: boxRef.current?.offsetTop,
                    xPercent: boxRef.current?.offsetLeft / boundary.width * 100,
                    yPercent: boxRef.current?.offsetTop / boundary.height * 100,
                    flip: isFlip
                };
                return ret;
            }
        };
    }, [x, y, w, h, isFlip]);
    console.log(selectedReporter);
    return (
        <div ref={boundaryRef} className={css.boundary}>
            <div className={css.copyright} />
            <div
                ref={boxRef}
                style={{
                    width: w,
                    height: h,
                    left: x,
                    top: y
                }}
                className={css.relative}
                {...registDragEvent(({deltaX, deltaY}) => {
                    if (!boundaryRef.current || !boxRef.current) return;
                    const boundary = boundaryRef.current.getBoundingClientRect();
                    setConfig({
                        x: inrange(x + deltaX, 0, boundary.width - w),
                        y: inrange(y + deltaY, 0, boundary.height - h),
                        w,
                        h,
                    });
                })}
            >
                {/* 이미지 */}
                <div className={classNames(css.box, {[css.flip]: isFlip})}>
                    <div className={css.flipIcon} onClick={() => setFlip(!isFlip)}>
                        <Icon size="tiny">{'flip'}</Icon>
                    </div>
                    <img ref={imgRef} alt="" src={selectedReporter.fullProfileUrl} style={{width: '100%', height: 'auto'}} />
                </div>
                {/* 좌상단 */}
                <div
                    className={css.topleft}
                    {...registDragEvent(({deltaX, deltaY, startX, startY, startRight, startBottom}) => {
                        if (!boundaryRef.current) return;

                        const boundary = boundaryRef.current.getBoundingClientRect();
                        setConfig({
                            x: inrange(startX + deltaY / boxRatio, 0, startRight - MIN_W),
                            y: inrange(startY + deltaY, 0, startBottom - MIN_W * boxRatio),
                            w: inrange((h - deltaY) / boxRatio, 0, boundary.height / boxRatio),
                            h: inrange(h - deltaY, 0, boundary.height)
                        });
                    }, true)}
                />
                {/* 우상단 */}
                <div
                    className={css.topright}
                    {...registDragEvent(({deltaX, deltaY, startY, startBottom}) => {
                        if (!boundaryRef.current) return;

                        const boundary = boundaryRef.current.getBoundingClientRect();
                        setConfig({
                            x,
                            y: inrange(startY + deltaY, 0, startBottom - MIN_H),
                            w: inrange((h - deltaY) / boxRatio, MIN_H / boxRatio, boundary.height / boxRatio),
                            h: inrange(h - deltaY, MIN_H, (boundary.width - x) * boxRatio)
                        });
                    }, true)}
                />
                {/* 좌하단 */}
                <div
                    className={css.bottomleft}
                    {...registDragEvent(({deltaX}) => {
                        if (!boundaryRef.current) return;

                        const boundary = boundaryRef.current.getBoundingClientRect();

                        setConfig({
                            x: inrange(x + deltaX, 0, x + w - MIN_W),
                            y,
                            w: inrange(w - deltaX, MIN_W, (boundary.height - y) / boxRatio),
                            h: inrange((w - deltaX) * boxRatio, MIN_W * boxRatio, boundary.height - y)
                        });
                    }, true)}
                />
                {/* 우하단 */}
                <div
                    className={css.bottomright}
                    {...registDragEvent(({deltaX}) => {
                        if (!boundaryRef.current || !boxRef.current) return;

                        const boundary = boundaryRef.current.getBoundingClientRect();
                        setConfig({
                            x,
                            y,
                            w: inrange(w + deltaX, MIN_W, (boundary.height - y) / boxRatio),
                            h: inrange((w + deltaX) * boxRatio, MIN_W * boxRatio, boundary.height - y)
                        });
                    }, true)}
                />
                {/* 상단 */}
                <div
                    className={css.topside}
                    {...registDragEvent(({deltaY}) => {
                        setConfig({
                            x,
                            y: inrange(y + deltaY, y, y + h - MIN_H),
                            w,
                            h: inrange(h - deltaY, MIN_H, w * originValues.ratio),
                        });
                    }, true)}
                />
                {/* 하단 */}
                <div
                    className={css.bottomside}
                    {...registDragEvent(({deltaY}) => {
                        setConfig({
                            x,
                            y,
                            w,
                            h: inrange(h + deltaY, MIN_H, w * originValues.ratio),
                        });

                    }, true)}
                />
                {/* 우측 */}
                {/*<div
                    className={css.rightside}
                    {...registDragEvent((deltaX) => {
                        if (!boundaryRef.current) return;

                        const boundary = boundaryRef.current.getBoundingClientRect();

                        setConfig({
                            x,
                            y,
                            w: inrange(w + deltaX, MIN_W, boundary.width - x - BOUNDARY_MARGIN),
                            h,
                        });
                    }, true)}
                />*/}
                {/* 좌측 */}
                {/*<div
                    className={css.leftside}
                    {...registDragEvent((deltaX) => {
                        setConfig({
                            x: inrange(x + deltaX, BOUNDARY_MARGIN, x + w - MIN_W),
                            y,
                            w: inrange(w - deltaX, MIN_W, x + w - BOUNDARY_MARGIN),
                            h,
                        });
                    }, true)}
                />*/}
            </div>
        </div>
    );
});

ImageResize.displayName = 'ImageResize';

export default ImageResize;
