import classNames from 'classnames';
import Icon from '../Icon/Icon';
import { rem } from 'polished';
import css from './StepItem.module.scss'
import Image from '../Image/Image';

const StepItem = ({
    icon,
    title,
    guidanceStr,
    selected,
    disabled,
    selectedValues,
    imgSrc,
    position,
    onClick
}: {
    icon?: string;
    title: string;
    guidanceStr: string;
    selected?: boolean;
    disabled?: boolean;
    selectedValues?: string[];
    imgSrc?: string;
    position?: {x: number; y: number; width: number; height: number; flip: boolean;} | null;
    onClick?: () => void;
}) => {
    const componentCss = classNames(css.stepItem, {
        [css.disabled]: disabled,
        [css.selected]: selected
    })
    return (
        <div className={componentCss} onClick={onClick}>
            <div className={css.info}>
                <div className={css.titleWrapper}>
                    {icon ? <Icon size="mini">{icon}</Icon> : null}
                    <div className={css.title}>{title}</div>
                </div>
                {!imgSrc && (!selectedValues || selectedValues.length < 1) ?
                    <div className={css.guidance}>{guidanceStr}</div> :
                    <>
                        {Array.isArray(selectedValues) && selectedValues.length > 0 ?
                            <ul>
                                {
                                    selectedValues?.map((item, index) => {
                                        return (
                                            <li key={index}>{item}</li>
                                        );
                                    })
                                }
                            </ul> : null
                        }
                    </>
                }
            </div>
            {
                imgSrc ?
                    <>
                        {position ?
                            <div className={css.boundary}>
                                <div className={css.resizedImage} style={{
                                    width: rem(position.width * 6.5 / 60),
                                    height: rem(position.height * 6.5 / 60),
                                    left: rem(position.x * 6.5 / 60),
                                    top: rem(position.y * 6.5 / 60),
                                    transform: position.flip ? 'scaleX(-1)' : 'scaleX(1)'
                                }}>
                                    <Image src={imgSrc} noDelay />
                                </div>
                            </div> :
                            <div className={css.image}>
                                <Image src={imgSrc} noDelay />
                            </div>
                        }
                    </> : null
            }
        </div>
    );
};

export default StepItem;
