import Button from '../common/Button/Button';
import Popup from '../common/Popup/Popup';

const DeletePopup = ({
    open,
    onClose,
    onConfirm
}: {
    open?: boolean;
    onClose: () => void;
    onConfirm: () => void;
}) => {
    return (
        <Popup
            open={open}
            content={'선택한 파일을 삭제 하시겠습니까?'}
            onClose={onClose}
            buttons={
                <>
                    <Button type="line" onClick={onClose}>{'취소'}</Button>
                    <Button onClick={onConfirm}>{'삭제'}</Button>
                </>
            }
        />
    );
};

export default DeletePopup;
