import { useCallback, useEffect, useState } from 'react';
import Meridiem from './Meridiem';
import TimeInput from './TimeInput';
import css from './TimePicker.module.scss';

interface Props {
  /** hour to display */
  hour?: number;
  /** minute to display */
  minute?: number;
  useMeridiem?: boolean;
  /** hour, minute, type change event */
  onChange?: ({hour, minute}: {hour: number, minute: number}) => void;
  /** hour, minute blur event */
  onBlur?: ({hour, minute}: {hour: number, minute: number}) => void;
}

const TimePicker = ({
    hour,
    minute,
    useMeridiem,
    onChange,
    onBlur
}: Props) => {
    const [time, setTime] = useState<{hour: number; minute: number;}>({
        hour: hour || 0,
        minute: minute || 0
    });

    const handleMeridiemChange = () => (e: any) => {
        setTime(
            {
                ...time,
                hour: e === 'post' ? time.hour + 12 : time.hour - 12,
            }
        );
    };

    const handleChange = (item: 'hour' | 'minute') => (e: any) => {
        const min = 0;
        const max = item === 'hour' ? 23 : 59;
        let value = parseInt(e?.currentTarget?.value, 10);

        if (isNaN(value)) {
            value = 0;
        }

        if (max < value) {
            value = max;
        }

        if (min > value) {
            value = min;
        }

        setTime(
            {
                ...time,
                [item]: value,
            }
        );
    };

    const handleUp = (item: 'hour' | 'minute') => () => {
        const max = item === 'hour' ? 23 : 59;

        let value = time[item];

        if (value === max) {
            value = 0; // 최대 값이면 0으로 설정
        } else {
            value += 1; // 아니면 1 증가
        }

        setTime(
            {
                ...time,
                [item]: value
            }
        );
    };

    const handleDown = (item: 'hour' | 'minute') => () => {
        const min = 0;
        let value = time[item];

        if (value === min) {
            value = item === 'hour' ? 23 : 59;
        } else {
            value -= 1; // 아니면 1 감소
        }

        setTime(
            {
                ...time,
                [item]: value
            }
        );
    };

    const handleBlur = () => {
        onBlur && onBlur(time);
    };

    useEffect(() => {
        onChange && onChange(time);
    }, [onChange, time]);

    return (
        <div className={css.timePicker}>
            <TimeInput
                onUp={handleUp('hour')}
                onDown={handleDown('hour')}
                onChange={handleChange('hour')}
                onBlur={handleBlur}
                value={time.hour}
            />
            <div className={css.delimeter}>:</div>
            <TimeInput
                onUp={handleUp('minute')}
                onDown={handleDown('minute')}
                onChange={handleChange('minute')}
                onBlur={handleBlur}
                value={time.minute}
            />
            {useMeridiem &&
                <Meridiem onChange={() => handleMeridiemChange()} />
            }
        </div>
    );
}

export default TimePicker;
