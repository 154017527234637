//import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
//import { useWavesurfer } from '@wavesurfer/react'
//import { formatTimeFromSec } from '../../../utils';
//import Icon from '../Icon/Icon';
//import css from './AudioWave.module.scss'
//import classNames from 'classnames';

//const AudioWave = forwardRef(({
//    containerClassName,
//    audioSrc,
//    barWidth = 1,
//    barGap = 1,
//    cursorWidth = 2,
//    noTimeInfo,
//    noControls,
//    height,
//    onChange
//}: {
//    containerClassName?: string;
//    audioSrc: string;
//    barWidth?: number;
//    barGap?: number;
//    cursorWidth?: number;
//    noTimeInfo?: boolean;
//    noControls?: boolean;
//    height?: number;
//    onChange?: (isPlaying: boolean) => void;
//}, ref) => {
//    const [duration, setDuration] = useState(0);
//    const audioWaveRef = useRef(null);
//    const containerRef = useRef(null);
//    const [progressColor, setProgressColor] = useState<CanvasGradient>();
//    //const [waveColor, setWaveColor] = useState<string | CanvasGradient>();

//    //useEffect(() => {
//    //    const width = (audioWaveRef?.current as any)?.getBoundingClientRect().width || 1500;
//    //    const canvas = document?.createElement('canvas');
//    //    const ctx = canvas?.getContext('2d');
//    //    const grd = ctx?.createLinearGradient(0, 0, width, 0);
//    //    grd?.addColorStop(0, '#7FFCC6');
//    //    grd?.addColorStop(1, '#A78EFF');
//    //    setProgressColor(grd);

//    //    // wave
//    //    setWaveColor(grd);
//    //}, []);
//    const waveColor = useMemo(() => {
//        const width = (audioWaveRef?.current as any)?.getBoundingClientRect().width || 1500;
//        const canvas = document.createElement('canvas');
//        const ctx = canvas.getContext('2d');
//        const grd = ctx?.createLinearGradient(0, 0, width, 0);
//        grd?.addColorStop(0, '#7FFCC6');
//        grd?.addColorStop(1, '#A78EFF');
//        return grd;
//    }, []);

//    const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
//        container: containerRef,
//        height,
//        waveColor: waveColor,
//        progressColor: progressColor,
//        cursorColor: '#8F84FF',
//        cursorWidth: cursorWidth,
//        barWidth: barWidth,
//        barGap: barGap,
//        barRadius: 500,
//        url: audioSrc,
//        backend: 'WebAudio'
//    });

//    wavesurfer?.on('ready', (e: any) => setDuration(e));

//    wavesurfer?.on('play', () => wavesurfer?.setOptions({waveColor: '#4C4E5C'}));

//    const onPlayPause = useCallback(() => {
//        console.log('play pause');
//        wavesurfer && wavesurfer.playPause();
//    }, [wavesurfer]);

//    useImperativeHandle(ref, () => {
//        return {
//            playPause: () => {
//                console.log('play pause2');
//                wavesurfer && wavesurfer.playPause();
//            }
//        };
//    }, [wavesurfer]);

//    useEffect(() => {
//        onChange && onChange(isPlaying);
//    }, [onChange, isPlaying])

//    console.log(audioSrc);

//    return (
//        <div ref={audioWaveRef} className={css.audioWave}>
//            <div ref={containerRef} className={classNames(css.container, containerClassName)}>
//            </div>
//            {!noControls ?
//                <div className={css.controls}>
//                    <Icon size="tiny" className={classNames(css.playIcon, {[css.disabled]: duration <= 0})} onClick={onPlayPause}>{isPlaying ? 'pauseCircle' : 'playCircle'}</Icon>
//                    {!noTimeInfo ?
//                        <div className={css.timeInfo}>
//                            <div className={css.time}>{formatTimeFromSec(currentTime)}</div>
//                            <div className={css.time}>{formatTimeFromSec(duration)}</div>
//                        </div> : null
//                    }
//                </div> : null
//            }
//        </div>
//    )
//});

//AudioWave.displayName = 'AudioWave';
//export default AudioWave;


import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState, useMemo } from 'react';
import { useWavesurfer } from '@wavesurfer/react';
import { formatTimeFromSec } from '../../../utils';
import Icon from '../Icon/Icon';
import css from './AudioWave.module.scss';
import classNames from 'classnames';

const AudioWave = forwardRef(({
    containerClassName,
    audioSrc,
    barWidth = 1,
    barGap = 1,
    cursorWidth = 2,
    noTimeInfo,
    noControls,
    height,
    onChange
}: {
    containerClassName?: string;
    audioSrc: string;
    barWidth?: number;
    barGap?: number;
    cursorWidth?: number;
    noTimeInfo?: boolean;
    noControls?: boolean;
    height?: number;
    onChange?: (isPlaying: boolean) => void;
}, ref) => {
    const [duration, setDuration] = useState(0);
    const audioWaveRef = useRef(null);
    const containerRef = useRef(null);

    // useMemo를 사용하여 그라데이션 색상을 한 번만 생성
    const waveColor = useMemo(() => {
        const width = (audioWaveRef?.current as any)?.getBoundingClientRect().width || 1500;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const grd = ctx?.createLinearGradient(0, 0, width, 0);
        grd?.addColorStop(0, '#7FFCC6');
        grd?.addColorStop(1, '#A78EFF');
        return grd;
    }, []);

    const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
        container: containerRef,
        height,
        waveColor,
        progressColor: waveColor,
        cursorColor: '#8F84FF',
        cursorWidth,
        barWidth,
        barGap,
        barRadius: 500,
        url: audioSrc,
        backend: 'WebAudio'
    });

    // 이벤트 핸들러를 한 번만 설정하도록 useEffect 사용
    useEffect(() => {
        if (wavesurfer) {
            wavesurfer.on('ready', () => setDuration(wavesurfer.getDuration()));
            wavesurfer.on('play', () => wavesurfer.setOptions({ waveColor: '#4C4E5C' }));
        }
        return () => wavesurfer?.destroy();
    }, [wavesurfer]);

    // playPause 함수
    const onPlayPause = useCallback(() => {
        wavesurfer && wavesurfer.playPause();
    }, [wavesurfer]);

    useImperativeHandle(ref, () => ({
        playPause: onPlayPause
    }), [onPlayPause]);

    // isPlaying 상태가 변경될 때만 onChange 호출
    useEffect(() => {
        onChange && onChange(isPlaying);
    }, [onChange, isPlaying]);

    return (
        <div ref={audioWaveRef} className={css.audioWave}>
            <div ref={containerRef} className={classNames(css.container, containerClassName)}></div>
            {!noControls && (
                <div className={css.controls}>
                    <Icon size="tiny" className={classNames(css.playIcon, { [css.disabled]: duration <= 0 })} onClick={onPlayPause}>
                        {isPlaying ? 'pauseCircle' : 'playCircle'}
                    </Icon>
                    {!noTimeInfo && (
                        <div className={css.timeInfo}>
                            <div className={css.time}>{formatTimeFromSec(currentTime)}</div>
                            <div className={css.time}>{formatTimeFromSec(duration)}</div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
});

AudioWave.displayName = 'AudioWave';
export default AudioWave;
