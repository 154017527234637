import Button from '../common/Button/Button';
import Popup from '../common/Popup/Popup';

const DownloadPopup = ({
    open,
    onClose,
    onConfirm
}: {
    open?: boolean;
    onClose: () => void;
    onConfirm: () => void;
}) => {
    return (
        <Popup
            open={open}
            content={'선택한 파일을\n다운로드 받으시겠습니까?'}
            onClose={onClose}
            buttons={
                <>
                    <Button type="line" onClick={onClose}>{'취소'}</Button>
                    <Button onClick={onConfirm}>{'확인'}</Button>
                </>
            }
        />
    );
};

export default DownloadPopup;
