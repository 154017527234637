import Tippy from '@tippyjs/react';
import * as Popper from '@popperjs/core';
import 'tippy.js/dist/tippy.css';
import './Tooltip.css';
import { forwardRef, useState } from 'react';

export type Placement = Popper.Placement;

//type Placement =
//  | 'auto'
//  | 'auto-start'
//  | 'auto-end'
//  | 'top'
//  | 'top-start'
//  | 'top-end'
//  | 'bottom'
//  | 'bottom-start'
//  | 'bottom-end'
//  | 'right'
//  | 'right-start'
//  | 'right-end'
//  | 'left'
//  | 'left-start'
//  | 'left-end';

const Popup = ({
    children,
    content,
    placement = 'bottom',
}: {
    children: JSX.Element;
    content: JSX.Element;
    placement?: Placement;
}) => {
    return (
        <Tippy
            placement={placement}
            content={content}
        >
            {children}
        </Tippy>
    );
};

export default Popup;
