import DatePicker from '../../components/common/DatePicker/DatePicker';
import Divider from '../../components/common/Divider/Divider';
import ProgressButton from '../../components/common/ProgressButton/ProgressButton';
import Text from '../../components/common/Text/Text';
import TextField from '../../components/common/TextField/TextField';
import ImageViewer from './ImageViewer';
import StyleList from '../common/StyleList';
import css from './AIImagePanel.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { setMenu } from '../../features/menu/menuSlice';
import { createImage, getStatus, getStyle, resetImageData, resetStatus, setDate, setEpisode, setImageDescription, setStatus, setStyle, setTitle } from '../../features/image/imageSlice';
import Standby from '../common/Standby';
import { abortAllRequests } from '../../utils/api';


const AIImagePanel = () => {
    const dispatch = useDispatch<AppDispatch>();
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [enable, setEnable] = useState<boolean>(false);
    const { bTitle, bDate, bEpisode, selectedStyle, imageDescription, styleList, dataInfo, resourceId } = useSelector((state: RootState) => state.image);
    const [progress, setProgress] = useState<number>(-1);
    const [creating, setCreating] = useState<boolean>(false);

    useEffect(() => {
        dispatch(setMenu('ai-image'));
        dispatch(resetImageData());
        dispatch(getStyle());

        return () => {
            abortAllRequests();
            dispatch(resetImageData());
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [dispatch]);

    useEffect(() => {
        if (styleList.length > 0) {
            dispatch(setStyle(styleList[0].id));
        }
    }, [dispatch, styleList]);

    useEffect(() => {
        if (bTitle && bDate && bEpisode && selectedStyle && imageDescription) {
            setEnable(true);
        } else {
            setEnable(false);
        }
    }, [bTitle, bDate, bEpisode, selectedStyle, imageDescription]);

    const checkCreateStatus = useCallback(async (resourceId: string) => {
        try {
            const response = await getStatus({resourceId});
            const {dataInfo, errMsg, progress, status} = response;

            setProgress(progress);
            if (status === 'succeeded') {
                setCreating(false);
                setProgress(-1);
                setEnable(false);
                dispatch(setStatus(dataInfo));
            } else if (status !== 'succeeded') {
                timeoutRef.current = setTimeout(() => checkCreateStatus(resourceId), 500); // 2초 후에 다시 확인
            } else {
                console.error('Error checking upload status:', errMsg);
                setCreating(false);
            }
        } catch (error) {
            console.error('Error checking create status: ', error);
            setCreating(false);
        }
    }, [dispatch]);

    useEffect(() => {
        if (resourceId) {
            setCreating(true);
            checkCreateStatus(resourceId);
        }
    }, [resourceId, checkCreateStatus]);

    const handleCreate = () => {
        dispatch(resetStatus());
        dispatch(createImage());
    };

    const handleChangeDate = (ev: any) => {
        dispatch(setDate(ev.toISOString()));
    };

    return (
        <div className={css.viewerPanel}>
            <div className={css.info}>
                <Text required>{'타이틀'}</Text>
                <TextField
                    disabled={creating}
                    value={bTitle}
                    onChange={(ev: any) => dispatch(setTitle(ev.target?.value))}
                />
                <div className={css.columns}>
                    <span>
                        <Text>{'방송 일자'}</Text>
                        <DatePicker
                            disabled={creating}
                            date={new Date(bDate)}
                            onChange={handleChangeDate}
                        />
                    </span>
                    <span>
                        <Text required>{'방송 회차'}</Text>
                        <TextField
                            type="number"
                            disabled={creating}
                            value={bEpisode}
                            onChange={(ev: any) => dispatch(setEpisode(ev.target?.value))}
                        />
                    </span>
                </div>
                <Divider />
                <Text desc="원하는 이미지 스타일을 선택해 주세요.">{'일러스트 스타일'}</Text>
                <div className={css.stylebox}>
                    <StyleList
                        data={styleList}
                        selected={selectedStyle}
                        onChange={(selected: string) => dispatch(setStyle(selected))}
                    />
                </div>
                <Text desc="원하는 이미지를 설명해 주세요.">{'텍스트 입력'}</Text>
                <TextField
                    className={css.explanation}
                    multiLine
                    value={imageDescription}
                    onChange={(ev: any) => dispatch(setImageDescription(ev.target.value))}
                />
                <div className={css.buttons}>
                    <ProgressButton
                        className={css.createBtn}
                        progress={progress}
                        disabled={!enable}
                        onClick={handleCreate}
                    >
                        {creating ? '이미지 생성중...' : '이미지 생성'}
                    </ProgressButton>
                </div>
            </div>
            <div className={css.container}>
                {creating ?
                    <Standby /> :
                    dataInfo?.length > 0 ?
                        <ImageViewer onRecreate={handleCreate} /> :
                        <Standby />
                }
            </div>
        </div>
    );
}

export default AIImagePanel;
