import React, { useEffect, useRef } from 'react';
import { Amplify } from 'aws-amplify';
import { I18n } from 'aws-amplify/utils';
import { Authenticator, Heading, ThemeProvider, translations } from '@aws-amplify/ui-react';
import { confirmSignIn, ConfirmSignInInput, getCurrentUser, signIn, SignInInput } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import './custom-styles.css';
import css from './BgAnimation.module.scss';

import awsConfig from '../../amplifyconfiguration.json';
import Logo from '../../components/common/Logo/Logo';

Amplify.configure(awsConfig);

I18n.putVocabularies(translations);
I18n.setLanguage('ko');
I18n.putVocabulariesForLanguage('ko', {
    'Signing in': '로그인 중',
    'Setup TOTP': 'TOTP 설정',
    Code: '코드'
});

const components = {
    SignIn: {
        Header() {
            return (
                <Heading>
                    <div className="slogan">{'AI가 완성하는 뉴스 영상, 더 쉽고 더 빠르게'}</div>
                    <Logo className="title" direction="horizontal" />
                </Heading>
            );
        }
    }
};

const Login = () => {
    const interRef = useRef(null);

    const formFields = {
        signIn: {
            username: {
                placeholder: '아이디를 입력해 주세요.',
                label: '아이디',
                isRequired: true
            },
            password: {
                placeholder: '비밀번호를 입력해 주세요.',
                label: '비밀번호',
                isRequired: true,
                hideShowPassword: true
            }
        },
        setupTotp: {
            confirmation_code: {
                label: '인증 코드',
                placeholder: '인증 코드를 입력해 주세요.',
                isRequired: true,
            },
        }
    };

    useEffect(() => {
        if (interRef.current) {
            const interBubble = interRef.current;
            let curX = 0;
            let curY = 0;
            let tgX = 0;
            let tgY = 0;

            const move = () => {
                curX += (tgX - curX) / 20;
                curY += (tgY - curY) / 20;
                (interBubble as any).style.transform = `translate3d(${Math.round(
                    curX
                )}px, ${Math.round(curY)}px, 0)`;
                requestAnimationFrame(() => {
                    move();
                });
            }

            const handleMouseMove = (event: any) => {
                tgX = event.clientX;
                tgY = event.clientY;
            }
            window.addEventListener("mousemove", handleMouseMove);

            move();

            return () => {
                window.removeEventListener("mousemove", handleMouseMove);
            }
        }
    }, []);

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const { username, userId, signInDetails } = await getCurrentUser(); // 로그인 상태 확인
                console.log(username, userId, signInDetails);
                // 로그인 되어있으면 "/" 경로로 유지
                window.location.href = window.location.origin;
            } catch {
                console.log('Not logged in.');
            }
        };

        checkAuthStatus();
    }, []);

    const customServices = {
        // ID, PW Login
        async handleSignIn (input: SignInInput) {
            const { username, password } = input;

            return await signIn({username, password})
                .then(res => {
                    console.log('signIn : ', res);
                    return res;
                })
                .catch(err => {
                    switch (err.name) {
                    case 'UserNotFoundException':
                        throw new Error('사용자를 찾을 수 없습니다.');
                    case 'NotAuthorizedException':
                        throw new Error('비밀번호가 틀렸습니다. 다시 시도해 주세요.');
                    case 'UserNotConfirmedException':
                        throw new Error('이메일을 다시 확인하고 인증을 완료하세요.');
                    case 'PasswordResetRequiredException':
                        throw new Error('비밀번호 재설정이 필요합니다.');
                    default:
                        throw new Error('로그인에 실패했습니다. 다시 시도해 주세요.');
                    }
                });
        },
        // MFA
        async handleConfirmSignIn (input: ConfirmSignInInput) {
            return await confirmSignIn(input)
                .then(res => {
                    if (res.isSignedIn && res.nextStep?.signInStep === 'DONE') {
                        window.location.href = window.location.origin;
                    }
                    return res;
                })
                .catch(err => {
                    switch (err.name) {
                    case 'InvalidParameterException':
                        throw new Error('인증 코드가 유효하지 않습니다. 다시 입력해 주세요.');
                    case 'EnableSoftwareTokenMFAException':
                        throw new Error('잘못된 인증코드가 입력되었습니다. 다시 확인해 주세요.');
                    default:
                        throw new Error('인증번호 확인에 실패했습니다. 다시 시도해 주세요.');
                    }
                });
        }
    };

    return (
        <>
            <ThemeProvider>
                <Authenticator components={components} formFields={formFields} hideSignUp services={customServices} />
            </ThemeProvider>
            <div className={css.bg}>
                <svg viewBox="0 0 1920 1080" xmlns='http://www.w3.org/2000/svg'>
                    <filter id='noiseFilter'>
                        <feTurbulence
                            type='fractalNoise'
                            baseFrequency='0.65'
                            numOctaves='3'
                            stitchTiles='stitch' />
                    </filter>
                    <rect width='100%' height='100%' filter='url(#noiseFilter)' />
                </svg>
                <div className={css.back} />
            </div>
            <div className={css.gradientBg}>
                <svg xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <filter id="goo">
                            <feGaussianBlur
                                in="SourceGraphic"
                                stdDeviation="10"
                                result="blur"
                            />
                            <feColorMatrix
                                in="blur"
                                mode="matrix"
                                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
                                result="goo"
                            />
                            <feBlend in="SourceGraphic" in2="goo" />
                        </filter>
                    </defs>
                </svg>
                <div className={css.gradientsContainer}>
                    <div className={css.g1}></div>
                    <div className={css.g2}></div>
                    <div className={css.g3}></div>
                    <div className={css.g5}></div>
                    <div ref={interRef} className={css.interactive}></div>
                </div>
            </div>
        </>
    );
};

export default Login;
