import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GET, POST } from '../../utils/api';

interface dataInfoType {
    resourceId: string;
    url: string;
}

interface dataType {
    bTitle: string;
    bDate: string;
    bEpisode: string;
    selectedStyle: string;
    imageDescription: string;
    dataInfo: dataInfoType[];
    resourceId: string;
}

export interface styleListType {
    id: string;
    label: string;
    src: string;
}
export interface imageDataType extends dataType{
    //data: dataType
    styleList: styleListType[];
}

export const createImage = createAsyncThunk('/ai-image/create-image', async (_, {getState}) => {
    const state = (getState() as any).image;
    const params = {
        broadDate: state.bDate,
        broadEpisode: state.bEpisode,
        desc: state.imageDescription,
        styleId: state.selectedStyle,
        title: state.bTitle,
        userId: 'test'
    };
    const res = await POST('ai-image/create-image', params);
    if (!res.errMsg && res.resourceId) {
        return res.resourceId;
    } else {
        return '';
    }
});

export const saveImage = async ({resourceId}: {resourceId: string}) => {
    const params = {
        userId: 'test',
        resourceId
    }
    //const formData = new FormData();
    //formData.append('userId', 'test');
    //formData.append('resourceId', resourceId);
    const res = await POST('ai-image/save-image', params);
    return res;
};

export const getStyle = createAsyncThunk('/ai-image/get-style', async () => {
    const params = new URLSearchParams({
        userId: 'text'
    });
    const res = await GET('ai-image/get-style', params);
    if (!res.errMsg && Array.isArray(res.styleList) && res.styleList) {
        return res.styleList;
    } else {
        return [];
    }
});

export const getStatus = async ({resourceId}: {resourceId: string}) => {
    const params = new URLSearchParams({
        userId: 'text',
        resourceId
    });
    return await GET('ai-image/get-status', params);
};

export const initialState: imageDataType = {
    bTitle: '',
    bDate: new Date().toISOString(),
    bEpisode: '',
    selectedStyle: '',
    imageDescription: '',
    dataInfo: [],
    styleList: [],
    resourceId: ''
};

export const imageSlice = createSlice({
    name: 'image',  // image creation
    initialState,
    reducers: {
        setTitle: (state: imageDataType, action: PayloadAction<string>) => {
            state.bTitle = action.payload;
        },
        setDate: (state: imageDataType, action: PayloadAction<string>) => {
            state.bDate = action.payload;
        },
        setEpisode: (state: imageDataType, action: PayloadAction<string>) => {
            state.bEpisode = action.payload;
        },
        setStyle: (state: imageDataType, action: PayloadAction<string>) => {
            state.selectedStyle = action.payload;
        },
        setImageDescription: (state: imageDataType, action: PayloadAction<string>) => {
            state.imageDescription = action.payload;
        },
        setStatus: (state: imageDataType, action: PayloadAction<dataInfoType[]>) => {
            state.dataInfo = action.payload;
            state.resourceId = '';
        },
        resetStatus: (state: imageDataType) => {
            state.dataInfo = [];
        },
        resetImageData: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(createImage.fulfilled, (state, action) => {
            state.resourceId = action.payload;
        });

        builder.addCase(getStyle.fulfilled, (state, action) => {
            state.styleList = action.payload;
        });

        //builder.addCase(getStatus.fulfilled, (state, action) => {
        //    state.status = action.payload;
        //});
    }
});

export const {
    setTitle,
    setDate,
    setEpisode,
    setStyle,
    setImageDescription,
    setStatus,
    resetStatus,
    resetImageData
} = imageSlice.actions;

export default imageSlice.reducer;
