import classNames from 'classnames';
import Icon from '../common/Icon/Icon';
import Image from '../common/Image/Image';
import css from './StyleItem.module.scss';

const StyleItem = ({
    type = 'base',
    imageUrl,
    label,
    selected,
    onClick
}: {
    type?: 'base' | 'showAll' | 'ready';
    imageUrl?: string;
    label: string;
    selected?: boolean;
    onClick?: () => void;
}) => {
    const componentCss = classNames(css.styleItem, {
        [css.noLabel]: type === 'ready' || type === 'showAll',
        [css.selected]: selected,
        [css.placeholder]: !!imageUrl
    });

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className={componentCss} onClick={handleClick}>
            {type === 'showAll' ?
                <div className={css.showAll}>
                    <Icon size="tiny">{'add'}</Icon>
                    <span>{'모두보기'}</span>
                </div> :
                type === 'ready' ?
                    <div className={css.ready}>
                        <Icon size="tiny">{'construction'}</Icon>
                        <span>{'준비중'}</span>
                    </div> :
                    <>
                        {imageUrl ?
                            <div className={css.image}>
                                <Image src={imageUrl} />
                            </div> :
                            <div className={css.placeholder} />
                        }
                    </>
            }
            <div className={css.label}>
                <span>{label}</span>
            </div>
        </div>
    );
};

export default StyleItem;
