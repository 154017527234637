import { useCallback, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReporterItem from '../../components/AiHuman/ReporterItem';
import Button from '../../components/common/Button/Button';
import Divider from '../../components/common/Divider/Divider';
import Slider from '../../components/common/Slider/Slider';
import TextField from '../../components/common/TextField/TextField';
import { getReporter, reporterType } from '../../features/common/commonSlice';
import { AppDispatch, RootState } from '../../store';
import VideoPlayer from '../AIVideo/VideoPlayer';
import css from './ReporterSelection.module.scss'

const LabeledComponent = ({
    children,
    label
}: {
    children: JSX.Element;
    label: string;
}) => {
    return (
        <div className={css.labeledComponent}>
            <div className={css.label}>{label}</div>
            <div className={css.comp}>
                {children}
            </div>
        </div>
    );
}

const ReporterSelection = forwardRef(({
    reporterSpeed,
    onComplete
}: {
    reporterSpeed: number;
    onComplete: (ev: reporterType) => void;
}, ref) => {
    const dispatch = useDispatch<AppDispatch>();
    const { reporterList } = useSelector((state: RootState) => state.common);
    const [rate, setRate] = useState<number>(reporterSpeed);
    const [selected, setSelected] = useState<any>();
    const handleChange = (ev: number) => {
        setRate(ev);
    };

    const complete = useCallback(() => {
        onComplete && onComplete({...selected, speed: rate});
    }, [rate, selected, onComplete]);

    useImperativeHandle(ref, () => {
        return {
            reset: () => {
                setRate(reporterSpeed);
                setSelected(reporterList[0]);
            }
        };
    });

    useEffect(() => {
        dispatch(getReporter());
    }, []);

    useEffect(() => {
        if (reporterList.length > 0) {
            setSelected(reporterList[0]);
        }
    }, [reporterList]);

    return (
        <div className={css.reporterSelection}>
            <div className={css.selected}>
                <div className={css.selectedContainer}>
                    <div className={css.playerWrapper}>
                        <VideoPlayer
                            url={selected?.src}
                            thumbnail={selected?.profileUrl}
                            noHandle
                            noTimeInfo
                            showControlAlways
                            controlsClassName={css.playerControls}
                            rate={rate}
                            backgroundColor="#CCCDD3"
                        />
                    </div>
                    <div className={css.speedWrapper}>
                        <LabeledComponent label="이름">
                            <TextField onChange={null} readonly value={selected?.name} />
                        </LabeledComponent>
                        <LabeledComponent label="보도국">
                            <TextField onChange={null} readonly value={selected?.broadcast}/>
                        </LabeledComponent>
                        <LabeledComponent label="배속">
                            <Slider
                                className={css.speed}
                                min={0.75}
                                max={2.0}
                                marks={{
                                    0.75: " ",
                                    1.0: " ",
                                    1.25: " ",
                                    1.5: " ",
                                    1.75: " ",
                                    2.0: " ",
                                }}
                                step={0.5}
                                defaultValue={1.0}
                                value={rate}
                                showValue
                                valueLabel='배속'
                                onChange={handleChange}
                            />
                        </LabeledComponent>
                        <LabeledComponent label="">
                            <Button marked className={css.button} disabled={!selected?.name || !selected?.broadcast} onClick={complete}>{'선택 완료'}</Button>
                        </LabeledComponent>
                    </div>
                </div>
                <Divider />
            </div>
            <div className={css.reporterList}>
                <div className={css.list}>
                    {
                        reporterList.map((item, index) => {
                            return (
                                <ReporterItem
                                    key={item.id}
                                    src={item.src}
                                    title={item.name}
                                    thumbnail={item.profileUrl}
                                    selected={selected?.id === item.id}
                                    onClick={() => setSelected(item)}
                                />
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
});

ReporterSelection.displayName = 'ReporterSelection';

export default ReporterSelection;
