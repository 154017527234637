import { useState } from 'react';
import ReactPlayer from 'react-player';
import Standby from './Standby';
import css from './InProgress.module.scss'


const InProgress = ({
    content
}: {
    content?: string;
}) => {
    const [isErr, setErr] = useState(false);
    return (
        <div className={css.inProgress}>
            {isErr ?
                <Standby /> :
                <div className={css.video}>
                    <ReactPlayer
                        url={'https://d2j19zf65kld7y.cloudfront.net/1_main_ID.mp4'}
                        width={'100%'}
                        height={'100%'}
                        playing
                        controls={false}
                        loop
                        muted
                        onError={() => setErr(true)}
                        onReady={() => setErr(false)}
                    />
                </div>
            }
            {/*<div className={css.content}>
                {content}
                <span>{'* 브라우저를 닫거나 화면을 벗어나면 작업 내용이 사라지니 잠시만 기다려주세요.'}</span>
            </div>*/}
        </div>

    );
};

export default InProgress;
