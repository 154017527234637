import { useEffect, useState } from 'react';
import RCSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './Slider.css';
import classNames from 'classnames';

const Slider = ({
    className,
    readonly,
    min = 0,
    max = 100,
    step = 1,
    value,
    defaultValue,
    marks,
    showValue,
    valueLabel = '',
    trackStyle,
    railStyle,
    onChange
}: {
    className?: string;
    readonly?: boolean;
    min?: number;
    max?: number;
    step?: number;
    value?: number;
    defaultValue?: number;
    marks?: any;
    showValue?: boolean;
    valueLabel?: string;
    trackStyle?: [object] | object;
    railStyle?: object;
    onChange?: (v: number) => void;
}) => {
    const [progress, setProgress] = useState<number>(defaultValue || min);

    useEffect(() => {
        setProgress(value || 0);
    }, [value]);

    const handleChange = (value: number | number[]) => {
        if (typeof value === 'number') {
            setProgress(value);
            onChange && onChange(value);
        }
    };

    return (
        <div className={classNames('slider', className)}>
            <RCSlider
                disabled={readonly}
                min={min}
                max={max}
                marks={marks}
                step={step}
                defaultValue={1.0}
                value={progress}
                trackStyle={trackStyle}
                railStyle={railStyle}
                onChange={handleChange}
            />
            {showValue && <div className="label">{`${value} ${valueLabel}`}</div>}
        </div>
    );
};

export default Slider;
