import axios from 'axios';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import {useDropzone} from 'react-dropzone';
import { API_URL } from '../../../utils/api';
import VideoPlayer from '../../../views/AIVideo/VideoPlayer';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Popup from '../Popup/Popup';
import Spinner from '../Spinner/Spinner';
import Guide from './Guide';
import css from './Uploader.module.scss';

const Uploader = ({
    type,
    className,
    data,
    useCheckPopup,
    onComplete,
    onUploadStart,
    onUploadEnd,
    onDelete
}: {
    type: 'image' | 'video',
    className?: string;
    data?: {
        filename: string;
        src: string;
        thumbnailUrl: string;
    };
    useCheckPopup?: boolean;
    onComplete?: (ev: {
        resourceId: string;
        thumbnailUrl: string;
        src: string;
        filename: string;
        checked?: boolean;
    }) => void;
    onUploadStart?: () => void;
    onUploadEnd?: () => void;
    onDelete?: () => void;
}) => {
    const componentCss = classNames(css.fileUploader, className);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [uploading, setUploading] = useState<boolean>(false);
    const [openUploadPopup, setUploadPopup] = useState<boolean>(false);
    const [openDeletePopup, setDeletePopup] = useState<boolean>(false);
    const [file, setFile] = useState<any>(null);
    const [error, setError] = useState<boolean>(false);

    const [controller, setController] = useState<AbortController>();
    const [getController, setGetController] = useState<AbortController>();

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                console.log('clear timeout')
                clearTimeout(timeoutRef.current);
            }
        }
    }, []);

    useEffect(() => {
        return () => {
            console.log('[Unmount] Cancel to requests.');
            if (controller) {
                controller.abort();
            }

            if (getController) {
                getController.abort();
            }
        };
    }, []);

    const checkUploadStatus = async (resourceId: string) => {
        const controller = new AbortController();
        setGetController(controller);
        // TODO: user ID
        try {
            const response = await axios.get(
                `${API_URL}/common/get-upload-status?userId=test&resourceId=${resourceId}`,
                {
                    signal: controller.signal
                }
            );
            const { status, dataInfo, errMsg } = response.data;
            // ready, succeeded, failed
            if (status === 'succeeded') {
                setUploading(false);
                const fileInfo = {
                    resourceId,
                    thumbnailUrl: dataInfo.thumbnailUrl,
                    src: dataInfo.src,
                    filename: dataInfo.filename,
                    checked: useCheckPopup
                };
                onComplete && onComplete(fileInfo);
                //setFile(null);
            } else if (status !== 'succeeded') {
                timeoutRef.current = setTimeout(() => checkUploadStatus(resourceId), 1000); // 2초 후에 다시 확인
            } else {
                console.error('Error checking upload status:', errMsg);
                setUploading(false);
                onUploadEnd && onUploadEnd();
            }
        } catch (error) {
            console.error('Error checking upload status: ', error);
            setUploading(false);
            onUploadEnd && onUploadEnd();
            setError(true);
        }
    };

    //const upload = useCallback(async () => {
    //    const formData = new FormData();
    //    formData.append('userId', 'test');
    //    formData.append('type', type);
    //    formData.append('file', file);
    //    onUploadStart && onUploadStart();
    //    setUploading(true);
    //    await axios.post(`${API_URL}/common/upload-resource`, formData, {
    //        headers: {
    //            'Content-Type': 'multipart/form-data'
    //        }
    //    }).then(res => {
    //        if ((res.status === 200 || res.status === 202) && res.data.resourceId) {
    //            checkUploadStatus(res.data.resourceId);
    //        } else {
    //            console.error('Error uploading file:', res.data.errMsg);
    //            setUploading(false);
    //        }
    //    }).catch(err => {
    //        console.error('Error uploading file:', err);
    //        setUploading(false);
    //    });
    //}, [file]);

    const {getRootProps, getInputProps, open, isDragAccept, isDragReject} = useDropzone({
        accept: type === 'image' ? {
            'image/*': []
        } : {
            'video/mp4': []
        },
        noClick: true,
        multiple: false,
        onDrop: async (acceptedFiles) => {
            const newController = new AbortController();
            setController(newController);

            const formData = new FormData();
            formData.append('userId', 'test');
            formData.append('type', type);
            formData.append('file', acceptedFiles[0]);
            onUploadStart && onUploadStart();
            setUploading(true);
            await axios.post(`${API_URL}/common/upload-resource`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                signal: newController.signal
            }).then(res => {
                if ((res.status === 200 || res.status === 202) && res.data.resourceId) {
                    checkUploadStatus(res.data.resourceId);
                } else {
                    console.error('Error uploading file:', res.data.errMsg);
                    setUploading(false);
                }
            }).catch(err => {
                if (axios.isCancel(err)) {
                    console.log('[Canceled API] upload-resource');
                }
                console.error('Error uploading file:', err);
                setUploading(false);
                setError(true);
            });
        }
    });

    //useEffect(() => {
    //    if (!file) return;

    //    if (useCheckPopup) {
    //        setUploadPopup(true);
    //    } else {
    //        upload();
    //    }
    //}, [file, useCheckPopup]);

    const confirmUpload = () => {
        setUploadPopup(false);
        //upload();
    };

    const cancelUpload = () => {
        setUploadPopup(false);
        setFile(null);
    };

    const isValid = (obj?: {filename: string; src: string}) => obj && !!(obj?.filename && obj?.src);
    return (
        <div className={componentCss}>
            <div className={css.dropzoneWrapper} onClick={open}>
                <div {...getRootProps({className: classNames(
                    css.dropzone,
                    {
                        [css.dragAccept]: isDragAccept,
                        [css.dragReject]: isDragReject
                    }
                )})}>
                    <input {...getInputProps()} />
                    {uploading ?
                        <Spinner /> :
                        !isValid(data) ? <Guide type={type} /> : null
                    }
                </div>
                {!uploading && isValid(data) &&
                <div className={css.hoverarea}>
                    <Guide type={type} overlay />
                </div>
                }
            </div>
            {!uploading && data && isValid(data) &&
                <>
                    <VideoPlayer
                        className={css.player}
                        url={data.src}
                        backgroundImage={data?.thumbnailUrl}
                    />
                    <div className={css.fileName} onClick={open}>{data?.filename}</div>
                    <Icon size="tiny" className={css.delete} onClick={() => setDeletePopup(true)}>{'delete'}</Icon>
                </>
            }
            <Popup
                open={openUploadPopup}
                content={'첨부 파일 변경 시, 기존 내용은 모두 초기화됩니다.\n교체 하시겠습니까?'}
                onClose={() => {
                    setUploadPopup(false)}
                }
                buttons={
                    <>
                        <Button type="line" onClick={cancelUpload}>{'취소'}</Button>
                        <Button onClick={confirmUpload}>{'확인'}</Button>
                    </>
                }
            />
            <Popup
                open={openDeletePopup}
                content={'첨부된 동영상을 삭제하시겠습니까?'}
                onClose={() => {
                    setDeletePopup(false)}
                }
                buttons={
                    <>
                        <Button type="line" onClick={() => setDeletePopup(false)}>{'취소'}</Button>
                        <Button onClick={() => {
                            setDeletePopup(false);
                            onDelete && onDelete();
                        }}>{'확인'}</Button>
                    </>
                }
            />
            <Popup
                open={error}
                content={'첨부 파일 업로드를 실패했습니다. 다시 시도해 주세요.'}
                onClose={() => setError(false)}
                buttons={
                    <>
                        <Button onClick={() => setError(false)}>{'확인'}</Button>
                    </>
                }
            />
        </div>
    );
}

export default Uploader;
