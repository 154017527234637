import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DELETE, GET } from '../../utils/api';

export interface dataType {
    broadDate: string;
    broadEpisode: number;
    createdDate: string;
    enableDownload: boolean;
    id: string;
    info: string;
    progress: number;
    prompt: string;
    reportCnt: number;
    reporterName: string;
    status: string;
    styleName: string;
    thumbnail: string;
    srcUrl: string;
    title: string;
    type: string;
}

export interface dashboardDataType {
    data: dataType[];
}

const initialState: dashboardDataType = {
    data: []
};

interface SearchParams {
    type: 'all' | 'image' | 'video';
    createdDateFrom?: string;
    createdDateTo?: string;
    broadDate?: string;
    keywords?: string[];
    sortBy?: string;
    rowsPerPage?: number;
    sortOrder?: string;
    pageNum?: number;
}

export const searchResource = createAsyncThunk('/dashboard/search-resource', async (params: SearchParams) => {
    const {
        type,
        createdDateFrom = '',
        createdDateTo = '',
        broadDate = '',
        keywords = [],
        sortBy = 'createdDate',
        rowsPerPage,
        sortOrder = 'ASC',
        pageNum
    } = params;

    const formData = new FormData();
    formData.append('type', type);
    formData.append('userId', 'test');
    formData.append('createdDateFrom', createdDateFrom);
    formData.append('createdDateTo', createdDateTo);
    formData.append('userId', broadDate);
    keywords.forEach(word => {
        formData.append('keyword', word);
    });
    formData.append('sortBy', sortBy);
    formData.append('sortOrder', sortOrder);
    // optional
    if (rowsPerPage !== undefined) {
        formData.append('rowsPerPage', rowsPerPage.toString());
    }
    if (pageNum !== undefined) {
        formData.append('pageNum', pageNum.toString());
    }
    const res = await GET('dashboard/search-resource', formData);

    if (!res.errMsg && Array.isArray(res.items) && res.items) {
        return res.items;
    } else {
        return [];
    }
});

export const deleteResource = async ({id}: {id: string}) => {
    const params = {
        id,
        userId: 'text'
    };

    const res = await DELETE('dashboard/delete-resource', params);

    if (!res.errMsg) {
        return true;
    } else {
        return false;
    }
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        resetVideoInfo: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(searchResource.fulfilled, (state, action) => {
            state.data = action.payload;
        });
    }
});

export const {
    resetVideoInfo
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
