import classNames from 'classnames';
import Dropdown from '../../components/common/Dropdown/Dropdown';
import ReactTable, { TableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../components/common/Icon/Icon';
import ProgressBar from '../../components/common/ProgressBar/ProgressBar';
import { dataType, deleteResource } from '../../features/dashboard/dashboardSlice';
import { AppDispatch, RootState } from '../../store';
import css from './ListView.module.scss'
import { ReactNode, useCallback, useState } from 'react';
import { current } from '@reduxjs/toolkit';
import { rem, remToPx } from 'polished';
import FullScreenPopup from './FullScreenPopup';
import Popup from '../../components/common/Popup/Popup';
import Image from '../../components/common/Image/Image';
import CategoryOverlay from '../../components/common/CategoryOverlay/CategoryOverlay';
import { getTimeString } from '../../utils';
import { downloadResource } from '../../features/common/commonSlice';
import { DOWNLOAD } from '../../utils/api';
import DownloadPopup from '../../components/Dashboard/DownloadPopup';
import DeletePopup from '../../components/Dashboard/DeletePopup';
import NoData from './NoData';

const ProgressCell = ({
    status,
    progress
}: {
    status: string;
    progress: number;
}) => {
    return (
        <div className={classNames(css.progressCell, {[css.failed]: status === 'failed', [css.completed]: status === 'completed'})}>
            <div className={css.top}>
                <div className={css.statusIcon} />
                <div className={css.status}>{status === 'failed' ? 'Failed' : status === 'completed' ? 'Completed' : 'In progress'}</div>
                <div className={css.percent}>{`${progress}%`}</div>
            </div>
            <div>
                <ProgressBar
                    noHandle
                    noTimeInfo
                    percentValue={status === 'completed' ? 0 : progress}
                    min={0}
                    max={100}
                    step={0.1}
                    trackStyle={{
                        backgroundColor: status === 'failed' ? '#FF6C75' : status === 'completed' ? '#75757F' : '#8F84FF',
                    }}
                    railStyle={{
                        backgroundColor: status === 'completed' ? '#75757F' : '#5D6073',
                    }}
                />
            </div>
        </div>
    );
}

const Pagination = (props: any) => {
    console.log(props);
    const {rowCount, currentPage, rowsPerPage, onChangeRowsPerPage, onChangePage} = props;
    const totalPage = Math.ceil(rowCount / rowsPerPage);
    const [perPage, setPerPage] = useState({id: '20', label: '20'});

    const handlePrev = () => {
        if (currentPage === 1) return;
        onChangePage(currentPage -1, rowCount);
    };

    const handleNext = () => {
        if (currentPage === rowCount) return;
        onChangePage(currentPage + 1, rowCount);
    };

    return (
        <div className={css.pagination}>
            <span>{'Rows per page'}</span>
            <Dropdown
                className={css.perPage}
                direction="up"
                options={[
                    {id: '10', label: '10'},
                    {id: '15', label: '15'},
                    {id: '20', label: '20'},
                    {id: '25', label: '25'},
                    {id: '30', label: '30'}
                ]}
                selected={perPage?.id}
                onSelect={(ev: any) => {
                    setPerPage(ev);
                    onChangeRowsPerPage(parseInt(ev.id));
                }}
            />
            <div className={css.controls}>
                <div className={classNames(css.prev, {[css.disabled]: currentPage === 1})} onClick={handlePrev} />
                <div className={css.currentPage}><span>{`Page ${currentPage}`}</span>{` of ${totalPage}`}</div>
                <div className={classNames(css.next, {[css.disabled]: currentPage === totalPage})} onClick={handleNext} />
            </div>
        </div>
    );
};

const SortIcon = (props: any, order: any) => {
    console.log(props, order);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={'1.25rem'} height={'1.25rem'} viewBox="0 0 20 20" fill="none">
            <path d="M15 11.9038L5 11.9038L10 16.4998L15 11.9038Z" fill="#BDB6FF"/>
            <path d="M15 8.09596L10 3.5L5 8.09596L15 8.09596Z" fill="#5D6073"/>
        </svg>
    );
};

const ListView = () => {
    const { data } = useSelector((state: RootState) => state.dashboard);
    const [selectedItem, setSelected] = useState<any>(null);
    const [openDownloadPopup, setDownloadPopup] = useState<boolean>(false);
    const [openDeletePopup, setDeletePopup] = useState<boolean>(false);
    const [downloadId, setDownloadId] = useState<string>();
    const [deleteId, setDeleteId] = useState<string>();
    const [isErrImg, setErrImg] = useState(false);

    const handleFullScreen = (item: any) => {
        setSelected(item);
    };

    const handleDownload = useCallback(() => {
        if (downloadId) {
            downloadResource({
                resourceId: downloadId
            }).then(res => {
                console.log(res);
                DOWNLOAD(res);
            });
            setDownloadPopup(false);
            setDownloadId('');
        }
    }, [downloadId]);

    const handleCloseDownloadPopup = () => {
        setDownloadId('');
        setDownloadPopup(false)
    };

    const handleCloseDeletePopup = () => {
        setDeleteId('');
        setDeletePopup(false)
    };

    const handleDelete = useCallback(() => {
        if (deleteId) {
            deleteResource({id: deleteId});
            setDownloadId('');
        }
        setDeletePopup(false);
    }, [deleteId]);

    const handleErrImg = () => {
        setErrImg(true);
    };

    const handleLoadImg = () => {
        setErrImg(false);
    };

    const columns: TableColumn<dataType>[] = [
        {
            name: '종류',
            width: '6.25rem',
            cell: row => {
                return (
                    <div className={css.typeBox}>
                        <CategoryOverlay
                            color={row.type === 'image' ? 'green' : row.type === 'video' ? 'yellow' : undefined}
                        >
                            {row.type === 'image' ? 'AI 이미지' : row.type === 'video' ? 'AI 비디오' : ''}
                        </CategoryOverlay>
                    </div>
                );
            },
            sortable: false,
            center: true
        },
        {
            name: '',
            width: '7.625rem',
            cell: row => {
                return (
                    <div className={classNames(css.imgCell, {[css.placeholder]: !row.thumbnail})} onClick={() => handleFullScreen(row)}>
                        {row.thumbnail && <Image src={row.thumbnail} />}
                    </div>
                );
            }
        },
        {
            name: '생성일자',
            width: '9.375rem',
            cell: row => getTimeString(row.createdDate),
            sortable: true
        },
        {
            name: '스타일명',
            width: '7.5rem',
            selector: row => row.type === 'image' ? row.styleName : '-',
            sortable: true
        },
        {
            name: '프롬프트',
            selector: row => row.type === 'image' ? row.prompt : '-',
            sortable: true
        },
        {
            name: '타이틀',
            width: '7.5rem',
            selector: row => row.title,
            sortable: true
        },
        {
            name: '방송일자',
            width: '9.375rem',
            selector: row => getTimeString(row.broadDate),
            sortable: true
        },
        {
            name: '방송회차',
            width: '6.25rem',
            selector: row => `${row.broadEpisode}회`,
            sortable: true
        },
        {
            name: '',
            width: '8.125rem',
            cell: row => {
                return (
                    <div className={css.toolCell}>
                        <Icon>{'info'}</Icon>
                        <Icon disabled={!row.enableDownload} onClick={() => {
                            setDownloadId(row.id);
                            setDownloadPopup(true)
                        }}>{'download'}</Icon>
                        <Icon onClick={() => {
                            setDeleteId(row.id);
                            setDeletePopup(true)
                        }}>{'trash'}</Icon>
                    </div>
                );
            }
        }
    ];

    const style = {
        tableWrapper: {
            style: {
                height: '100%'
            }
        },
        table: {
            style: {
                width: '100%',
                backgroundColor: '#1E1E22'
            }
        },
        headRow: {
            style: {
                height: '2.5rem',
                backgroundColor: '#1E1E22',
                color: '#D4D0FF',
                borderBottom: '1px solid #444459',
                fontSize: '0.75rem'
            }
        },
        rows: {
            style: {
                backgroundColor: '#1E1E22',
                color: '#fff',
                height: '4.625rem',
                borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
                '&:not(:last-of-type)': {
                    borderBottom: '1px solid rgba(255, 255, 255, 0.05)'
                }
            },
            highlightOnHoverStyle: {
                backgroundColor: '#5D6073',
                color: '#fff',
                outline: 'none',
                borderBottom: '1px solid rgba(255, 255, 255, 0.05)'
            }
        },
        headCells: {
            style: {
                padding: '0 0.62rem',
                '& > div': {
                    '&:hover': {
                        opacity: 1
                    },
                    gap: '0.5rem',
                    '& > span': {
                        width: '1.25rem',
                        height: '1.25rem',
                        '& > svg': {
                            width: '1.25rem !important',
                            height: '1.25rem !important'
                        }
                    }
                }
            }
        },
        cells: {
            style: {
                padding: '0 0.62rem'
            }
        },
        pagination: {
            style: {
                backgroundColor: '#1E1E22',
                color: '#fff'
            },
            pageButtonsStyle: {
                color: '#fff',
                fill: '#fff',
                '&:disabled': {
                    cursor: 'unset',
                    color: 'rgba(255, 255, 255, 0.6)',
                    fill: 'rgba(255, 255, 255, 0.6)'
                },
                '&:hover:not(:disabled)': {
                    color: '#9FA0DA',
                    fill: '#9FA0DA',
                    backgroundColor: 'transparent',
                },
                '&:focus': {
                    backgroundColor: 'transparent'
                }
            },

        }

    };

    return (
        <>
            <div className={css.listView}>
                {data && data.length > 0 ?
                    <ReactTable
                        className={css.reactTable}
                        columns={columns}
                        customStyles={style}
                        data={data}
                        fixedHeader
                        pagination
                        highlightOnHover
                        paginationComponent={Pagination}
                        sortIcon={<SortIcon />}
                        noDataComponent={null}
                    /> :
                    <NoData />
                }
            </div>
            <FullScreenPopup
                type="video"
                open={!!selectedItem}
                onClose={() => setSelected(null)}
                handleDownload={() => setDownloadPopup(true)}
                data={selectedItem}
            />
            <DownloadPopup
                open={openDownloadPopup}
                onClose={handleCloseDownloadPopup}
                onConfirm={handleDownload}
            />
            <DeletePopup
                open={openDeletePopup}
                onClose={handleCloseDeletePopup}
                onConfirm={handleDelete}
            />
        </>
    );
};

export default ListView;
