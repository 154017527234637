import classNames from 'classnames';
import Icon from '../Icon/Icon';
import css from './SheetTab.module.scss'


const RemoveIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
        <g filter="url(#filter0_d_683_26804)">
            <circle cx="9.5" cy="7.5" r="7.5" fill="#4B4D5B"/>
        </g>
        <rect x="13" y="7" width="1" height="7" rx="0.5" transform="rotate(90 13 7)" fill="white"/>
        <defs>
            <filter id="filter0_d_683_26804" x="0" y="0" width="19" height="19" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="2"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_683_26804"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_683_26804" result="shape"/>
            </filter>
        </defs>
    </svg>
);

const SheetTab = ({
    active,
    activeLabel = '입력',
    deactiveLabel = '미입력',
    disabled,
    removable,
    selected,
    blockOnClick,
    onClick,
    onRemove
}: {
    active?: boolean;
    activeLabel?: string;
    deactiveLabel?: string;
    disabled?: boolean;
    removable?: boolean;
    selected?: boolean;
    blockOnClick?: boolean;
    onClick?: () => void;
    onRemove?: () => void;
}) => {
    const componentCss = classNames(css.sheetTab, {
        [css.active]: active,
        [css.disabled]: disabled,
        [css.selected]: selected,
        [css.blockOnClick]: blockOnClick
    });
    return (
        <div className={componentCss} onClick={onClick}>
            {removable ?
                <div className={css.remove} onClick={onRemove}>
                    <RemoveIcon />
                </div> : null}
            <Icon size="tiny">{'check'}</Icon>
            {active ? activeLabel : deactiveLabel}
        </div>
    );
};

export default SheetTab;
