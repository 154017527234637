import css from './Tag.module.scss';

const Tag = ({
    children,
    onRemove
}: {
    children: string;
    onRemove?: () => void;
}) => {
    return (
        <div className={css.tag}>
            <span>{children}</span>
            <div className={css.remove} onClick={onRemove} />
        </div>
    );
};

export default Tag;
