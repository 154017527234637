import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GET, POST } from '../../utils/api';

export interface reporterType {
    broadcast: string;
    gender?: string;
    id: string;
    name: string;
    speed: number;
    src: string;
    profileUrl: string;
    fullProfileUrl: string;
}

interface commonDataType {
    videoUrl: string;
    status: {
        errMsg: string;
        progress: number;
        result: number;
        status: string;
    };
    reporterList: reporterType[];
}

export const downloadTts = async ({userId, ttsId}: {userId: string; ttsId: string;}) => {
    const params = new URLSearchParams({
        userId,
        ttsId
    });
    return await GET('ai-human/get-article-tts-url', params);
};

export const downloadResource = async ({resourceId}: {resourceId: string}) => {
    const params = new URLSearchParams({
        userId: 'test',
        resourceId
    });
    return await GET('common/get-resource-url', params);
};

export const uploadResource = async ({type, file}: {type: 'image' | 'video', file: File}) => {
    const formData = new FormData();
    formData.append('userId', 'test');
    formData.append('type', type);
    formData.append('file', file);
    const res = await POST('common/upload-resource', formData);
    if (!res.errMsg && res.resourceId) {
        return res.resourceId;
    } else {
        return '';
    }
};

export const userAuthentication = async () => {
    const formData = new FormData();
    formData.append('userId', 'test');
    const res = await POST('common/user-authentication', formData);
    if (!res.errMsg) {
        return true;
    } else {
        return false;
    }
};

export const getReporter = createAsyncThunk('/common/get-reporter', async () => {
    const params = new URLSearchParams({
        userId: 'text'
    });
    const res = await GET('common/get-reporter', params);
    if (!res.errMsg && Array.isArray(res.reporterList) && res.reporterList) {
        return res.reporterList;
    } else {
        return [];
    }
});

export const getVideoUrl = createAsyncThunk('/ai-image/get-video-url', async ({id}: {id: string}) => {
    const params = new URLSearchParams({
        userId: 'text',
        resourceId: id
    });
    const res = await GET('common/get-video-url', params);
    if (!res.errMsg && res.url) {
        return res.url;
    } else {
        return '';
    }
});

export const getStatus = createAsyncThunk('/common/get-status', async ({id}: {id: string}) => {
    const params = new URLSearchParams({
        userId: 'text',
        resourceId: ''
    });
    const res = await GET('common/get-status', params);
    return res;
});

const initialState: commonDataType = {
    videoUrl: '',
    status: {
        errMsg: '',
        progress: -1,
        result: 0,
        status: ''
    },
    reporterList: []
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        resetCommonData: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getReporter.fulfilled, (state, action) => {
            state.reporterList = action.payload;
        });

        builder.addCase(getStatus.fulfilled, (state, action) => {
            state.status = action.payload;
        });

        builder.addCase(getVideoUrl.fulfilled, (state, action) => {
            state.videoUrl = action.payload;
        });
    }
});

export default commonSlice.reducer;
