import { useEffect, RefObject } from 'react';

export const useOnClickOutside = (
    ref: RefObject<HTMLElement> | RefObject<HTMLElement>[],
    handler: (event: TouchEvent | MouseEvent) => void,
    active = true
) => {
    useEffect(() => {
        if (!active) return;
        const listener = (event: TouchEvent | MouseEvent) => {
        // Do nothing if clicking ref's element or descendent elements
            const refs = Array.isArray(ref) ? ref : [ref];

            let contains = false;
            refs.forEach(r => {
                if (!r.current || r.current.contains(event.target as Node)) {
                    contains = true;
                    return;
                }
            });
            event.stopPropagation();
            if (!contains) handler(event);
        };

        document.addEventListener('mousedown', listener);

        return () => {
            if (!active) return;
            document.removeEventListener('mousedown', listener);
        };
    }, [ref, handler, active]);
};
