import { useRef } from 'react';
import ImageResize from '../../components/AiHuman/ImageResize';
import Button from '../../components/common/Button/Button';
import css from './ReporterResize.module.scss'


const ReporterResize = ({
    onComplete
}: {
    onComplete: (ev: {x: number; y: number; wPercent: number; hPercent: number; xPercent: number; yPercent: number; width: number; height: number; flip: boolean;}) => void;
}) => {
    const resizeRef = useRef(null);

    const complete = () => {
        const rect = (resizeRef.current as any)?.getCurrentValue();
        console.log((resizeRef.current as any)?.getCurrentValue());
        onComplete && onComplete(rect);
    };

    return (
        <div className={css.viewer}>
            <div className={css.top}>
                <Button marked onClick={complete}>{'설정 완료'}</Button>
            </div>
            <div className={css.resizeBox}>
                <ImageResize ref={resizeRef} />
            </div>
        </div>
    );
};

export default ReporterResize;
