import classNames from 'classnames';
import css from './Dropdown.module.scss';
import { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '../../../utils/hooks/useOnClickOutside';
import Icon from '../Icon/Icon';

export interface optionType {
    id: string | number;
    label: string;
    src?: string;
}

const Option = ({selected, disabled, label, onClick}: {selected?: boolean; disabled?: boolean; label: string; onClick: () => void;}) => {
    return (
        <div
            className={classNames(
                css.option,
                {
                    [css.selected]: selected,
                    [css.disabled]: disabled
                }
            )}
            onClick={onClick}
        >
            {label}
        </div>
    );
};

const Dropdown = ({
    className,
    menuClassName = '',
    disabled = false,
    options,
    placeholder,
    selected,
    selectedData,
    readOnly,
    menuComponent,
    optionComponent,
    icon,
    direction = 'down',
    onSelect
}: {
    className?: string;
    menuClassName?: string;
    disabled?: boolean;
    options: optionType[];
    placeholder?: string;
    selected?: string | number;
    selectedData?: optionType;
    readOnly?: boolean;
    menuComponent?: JSX.Element;
    optionComponent?: JSX.Element;
    icon?: string;
    direction?: 'up' | 'down';
    onSelect?: (ev: any) => void;
}) => {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<optionType>();
    const componentCss = classNames(css.dropdown, className, {
        [css.disabled]: disabled,
        [css.readOnly]: readOnly,
        [css.open]: isOpen,
        [css.upwards]: direction === 'up'
    });

    useEffect(() => {
        //if (!menuComponent) {
        const s = options.find(item => item.id === selected || item.id === selected);
        setSelectedItem(s);
        //}
    }, [selected, options, menuComponent]);

    //useEffect(() => {
    //    if (menuComponent) {
    //        setSelectedItem(selectedData);
    //    }
    //}, [selectedData, menuComponent]);

    const onChange = (ev: any) => {
        onSelect && onSelect(ev);
    };

    useOnClickOutside(ref, () => {
        setOpen(false);
    });

    const handleOpen = (ev: any) => {
        setOpen(!isOpen);
    };

    const handleSelect = (ev: optionType) => {
        setOpen(false);
        setSelectedItem(ev);
        onChange(ev);
    };

    return (
        <div className={componentCss}>
            <div className={css.control} onClick={handleOpen}>
                {icon ?
                    <Icon size="tiny">{icon}</Icon> : null
                }
                {selectedItem ?
                    <div className={css.selected}>{selectedItem?.label}</div> :
                    <div className={css.placeholder}>{placeholder}</div>
                }
                {
                    isOpen ?
                        <Icon className={css.arrow} size="tiny">{'upTriangle'}</Icon> :
                        <Icon className={css.arrow} size="tiny">{'downTriangle'}</Icon>
                }
            </div>
            {isOpen &&
                <div ref={ref} className={classNames(css.menu, menuClassName)}>
                    {menuComponent ?
                        menuComponent :
                        <>
                            {optionComponent ?
                                optionComponent :
                                <>
                                    {options?.map(item => {
                                        return (
                                            <Option
                                                key={item.id}
                                                {...item}
                                                selected={selectedItem?.id === item.id}
                                                onClick={() => handleSelect(item)}
                                            />
                                        );
                                    })}
                                </>
                            }
                        </>
                    }
                </div>
            }
        </div>
    );
}

export default Dropdown;
