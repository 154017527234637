import classNames from 'classnames';
import CategoryOverlay from '../CategoryOverlay/CategoryOverlay';
import Image from '../Image/Image';
import css from './ImageListItem.module.scss'

const ImageListItem = ({
    className,
    src,
    selected,
    category,
    onClick
}: {
    className?: string;
    src: string;
    selected?: boolean;
    category?: string;
    onClick?: () => void;
}) => {
    const componentCss = classNames(css.imageItem, className, {
        [css.selected]: selected
    });

    return (
        <div className={componentCss} onClick={onClick}>
            {category && <CategoryOverlay>{category}</CategoryOverlay>}
            {src && <Image src={src} />}
        </div>
    );
};

export default ImageListItem;
