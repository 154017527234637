import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface menuType {
    selected: 'dashboard' | 'ai-image' | 'ai-video' | 'ai-human' | 'ai-news'
}

const initialState: menuType = {
    selected: 'dashboard'
};

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setMenu: (state: menuType, action: PayloadAction<'dashboard' | 'ai-image' | 'ai-video' | 'ai-human' | 'ai-news'>) => {
            state.selected = action.payload;
        }
    },
    extraReducers: (builder) => {
        //
    }
});

export const {
    setMenu
} = menuSlice.actions;

export default menuSlice.reducer;
