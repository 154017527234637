import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { menuType, setMenu } from '../../features/menu/menuSlice';
import { AppDispatch, RootState } from '../../store';
import css from './Menu.module.scss';
import MenuItem from './MenuItem';
import Logo from '../common/Logo/Logo';
import { signOut } from 'aws-amplify/auth';
import { useState } from 'react';
import Popup from '../common/Popup/Popup';
import Button from '../common/Button/Button';
import { isEqual } from 'lodash';
import { initialState as imageInitialState } from '../../features/image/imageSlice';
import { initialState as videoInitialState } from '../../features/video/videoSlice';
import { initialState as humanInitialState } from '../../features/human/humanSlice';
import { initialState as newsInitialState } from '../../features/news/newsSlice';

const Menu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = window.location.pathname;
    const dispatch = useDispatch<AppDispatch>();
    const imageState = useSelector((state: RootState) => state.image);
    const videoState = useSelector((state: RootState) => state.video);
    const humanState = useSelector((state: RootState) => state.human);
    const newsState = useSelector((state: RootState) => state.news);

    const { selected } = useSelector((state: RootState) => state.menu);
    const [openLogoutPopup, setLogoutPopup] = useState<boolean>(false);
    const [openCheckPopup, setCheckPopup] = useState<boolean>(false);
    const [pendingMenu, setPendingMenu] = useState<'' | 'dashboard' | 'ai-image' | 'ai-video' | 'ai-human' | 'ai-news'>('');
    const isLoginPath = location.pathname.includes('login');

    const isValidState = (selected: string): selected is menuType['selected'] => {
        return ['dashboard', 'ai-image', 'ai-video', 'ai-human', 'ai-news'].includes(selected);
    }

    const handleClickMenu = ({selected}: {selected: string}) => {
        if (!isValidState(selected)) {
            console.error('Invalid menu state.');
            return;
        }

        //const pathname = window.location.pathname;

        //if (pathname.includes('dashboard')) {
        //    dispatch(setMenu(selected));
        //    navigate(`/${selected}`);
        //    return;
        //}

        //const stateMapping: { [key: string]: [any, any] } = {
        //    '/ai-image': [imageState, imageInitialState],
        //    '/ai-video': [videoState, videoInitialState],
        //    '/ai-human': [humanState, humanInitialState],
        //    '/ai-news': [newsState, newsInitialState],
        //};

        //const [currentState, initialState] = stateMapping[pathname] || [null, null];

        //const changed = currentState && initialState ? !isEqual(currentState, initialState) : false;

        if (!pathname.includes('dashboard')) {
            setPendingMenu(selected);
            setCheckPopup(true);
        } else {
            dispatch(setMenu(selected));
            navigate(`/${selected}`);
        }
    };

    const handleChangeMenu = () => {
        setCheckPopup(false);
        if (isValidState(pendingMenu)) {
            dispatch(setMenu(pendingMenu));
        }

        if (pathname.includes(pendingMenu)) {
            window.location.reload();
        } else {
            navigate(`/${pendingMenu}`);
        }

        setPendingMenu('');
    };

    const reload = () => {
        window.location.href = window.location.origin;
    };

    const handleLogout = async () => {
        await signOut()
            .then(res => {
                window.location.reload();
            })
            .catch(err => {
                window.location.reload();
            })
    };

    return (
        <>
            {!isLoginPath ?
                <aside>
                    <div className={css.logoItem}>
                        <Logo direction="vertical" onClick={reload} />
                    </div>
                    <MenuItem id="dashboard" icon="dashboard" label="대시보드" selected={selected === 'dashboard'} onClick={handleClickMenu} />
                    <MenuItem id="ai-image" icon="image" label="AI 이미지" selected={selected === 'ai-image'} onClick={handleClickMenu} />
                    <MenuItem id="ai-video" icon="video" label="AI 동영상" selected={selected === 'ai-video'} onClick={handleClickMenu} />
                    <MenuItem id="ai-human" icon="human" label="AI Human" selected={selected === 'ai-human'} onClick={handleClickMenu} />
                    <MenuItem id="ai-news" icon="headline" label="AI 뉴스제작" selected={selected === 'ai-news'} onClick={handleClickMenu} />
                    <MenuItem id="logout" icon="logout" label="로그아웃" onClick={() => setLogoutPopup(true)} className={css.logout} />
                </aside> : null
            }
            <Popup
                open={openLogoutPopup}
                content={'로그아웃 하시겠습니까?'}
                onClose={() => setLogoutPopup(false)}
                buttons={
                    <>
                        <Button type="line" onClick={() => setLogoutPopup(false)}>{'취소'}</Button>
                        <Button onClick={handleLogout}>{'확인'}</Button>
                    </>
                }
            />
            <Popup
                open={openCheckPopup}
                content={'변경 시, 기존 내용은 모두 초기화됩니다.\n변경 하시겠습니까?'}
                onClose={() => setCheckPopup(false)}
                buttons={
                    <>
                        <Button type="line" onClick={() => setCheckPopup(false)}>{'취소'}</Button>
                        <Button onClick={handleChangeMenu}>{'확인'}</Button>
                    </>
                }
            />
        </>
    );
}

export default Menu;
