import classNames from 'classnames';
import css from './CategoryOverlay.module.scss'

const cx = classNames.bind(css);
const CategoryOverlay = ({
    children,
    color = 'base',
    className
}: {
    children: string;
    color?: 'base' | 'blue' | 'yellow' | 'violet' | 'green';
    className?: string;
}) => {
    const componentCss = cx(css.categoryOverlay, className, {
        [css[color]]: color && color !== 'base'
    });
    return (
        <div className={componentCss}>{children}</div>
    );
};

export default CategoryOverlay;
