import classNames from 'classnames';
import css from './Spinner.module.scss';

const Spinner = ({
    blockOnClick,
    scrim
}: {
    blockOnClick?: boolean;
    scrim?: boolean;
}) => {
    const componentCss = classNames(css.container, {
        [css.scrim]: scrim,
        [css.blockOnClick]: blockOnClick
    })
    return (
        <div className={componentCss}>
            <div className={css.spinner} />
        </div>
    );
}

export default Spinner;
