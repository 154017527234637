import Logo from '../../components/common/Logo/Logo';
import css from './Standby.module.scss'


const Standby = () => {
    return (
        <div className={css.standby}>
            <div className={css.bg}>
                <Logo direction="horizontal" />
            </div>
        </div>
    );
};

export default Standby;
