import css from './Toast.module.scss'


const Toast = ({
    open,
    children
}: {
    open?: boolean;
    children: string;
}) => {
    return (
        <>
            {open ?
                <div className={css.toast}>
                    <span>
                        {children}
                    </span>
                </div> : null
            }
        </>
    );
};

export default Toast;
