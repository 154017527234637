import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from '../../components/common/DatePicker/DatePicker';
import Divider from '../../components/common/Divider/Divider';
import Uploader from '../../components/common/Uploader/Uploader';
import ProgressButton from '../../components/common/ProgressButton/ProgressButton';
import Tabs from '../../components/common/Tabs/Tabs';
import Text from '../../components/common/Text/Text';
import TextField from '../../components/common/TextField/TextField';
import { setMenu } from '../../features/menu/menuSlice';
import { createVideo, getStatus, getStyle, resetStatus, resetVideoData, setDate, setEpisode, setAttachedImg, setAttachedPic, setStatus, setStyleId, setTitle } from '../../features/video/videoSlice';
import { AppDispatch, RootState } from '../../store';
import Standby from '../common/Standby';
import StyleList from '../common/StyleList';
import css from './AIVideoPanel.module.scss';
import VideoViewer from './VideoViewer';
import { abortAllRequests } from '../../utils/api';

const AIVideoPanel = () => {
    const dispatch = useDispatch<AppDispatch>();
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const { bDate, bEpisode, bTitle, styleList, styleId, attachedImg, attachedPic, dataInfo, resourceId } = useSelector((state: RootState) => state.video);
    const [activeTab, setTab] = useState<string>('image');
    const [progress, setProgress] = useState<number>(-1);
    const [creating, setCreating] = useState<boolean>(false);
    const [enable, setEnable] = useState<boolean>(false);

    useEffect(() => {
        dispatch(setMenu('ai-video'));
        dispatch(resetVideoData());
        dispatch(getStyle());

        return () => {
            abortAllRequests();
            dispatch(resetVideoData());
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [dispatch]);

    useEffect(() => {
        if (styleList.length > 0) {
            dispatch(setStyleId(styleList[0].id));
        }
    }, [dispatch, styleList]);

    useEffect(() => {
        const isImageTab = activeTab === 'image';
        const isFormValid = bTitle && bDate && bEpisode && (isImageTab ? attachedImg.thumbnailUrl : attachedPic.thumbnailUrl && styleId);
        setEnable(!!isFormValid);
    }, [activeTab, bTitle, bDate, bEpisode, styleId, attachedImg, attachedPic]);

    const checkCreateStatus = useCallback(async (resourceId: string) => {
        try {
            const response = await getStatus({resourceId});
            const {dataInfo, errMsg, progress, status} = response;

            setProgress(progress);
            if (status === 'succeeded') {
                setCreating(false);
                setProgress(-1);
                setEnable(false);
                dispatch(setStatus(dataInfo));
            } else if (status !== 'succeeded') {
                timeoutRef.current = setTimeout(() => checkCreateStatus(resourceId), 500); // 2초 후에 다시 확인
            } else {
                console.error('Error checking upload status:', errMsg);
                setCreating(false);
            }
        } catch (error) {
            console.error('Error checking create status: ', error);
            setCreating(false);
        }
    }, [dispatch]);

    useEffect(() => {
        if (resourceId) {
            setCreating(true);
            checkCreateStatus(resourceId);
        }
    }, [resourceId, checkCreateStatus]);

    const handleCreate = () => {
        dispatch(resetStatus());
        dispatch(createVideo({type: activeTab}));
    };

    const handleChangeDate = (ev: any) => {
        dispatch(setDate(ev.toISOString()));
    };

    const handleSetImg = useCallback((ev: {
        resourceId: string;
        thumbnailUrl: string;
        src: string;
        filename: string;
    }) => {
        dispatch(setAttachedImg({...ev}));
    }, [dispatch]);

    const handleSetPic = useCallback((ev: {
        resourceId: string;
        thumbnailUrl: string;
        src: string;
        filename: string;
    }) => {
        dispatch(setAttachedPic({...ev}));
    }, [dispatch]);

    return (
        <div className={css.viewerPanel}>
            <div className={css.info}>
                <Text required>{'타이틀'}</Text>
                <TextField
                    disabled={creating}
                    value={bTitle}
                    onChange={(ev: any) => dispatch(setTitle(ev.target?.value))}
                />
                <div className={css.columns}>
                    <span>
                        <Text>{'방송 일자'}</Text>
                        <DatePicker
                            disabled={creating}
                            date={new Date(bDate)}
                            onChange={handleChangeDate}
                        />
                    </span>
                    <span>
                        <Text required>{'방송 회차'}</Text>
                        <TextField
                            type="number"
                            disabled={creating}
                            value={bEpisode}
                            onChange={(ev: any) => dispatch(setEpisode(ev.target?.value))}
                        />
                    </span>
                </div>
                <Divider />
                <Tabs
                    options={[
                        {value: 'image', label: '이미지'},
                        {value: 'picture', label: '사진'}
                    ]}
                    onSelect={(selected: string) => setTab(selected)}
                />
                {
                    activeTab === 'image' ?
                        <>
                            <Text desc="동영상으로 만들기 원하는 이미지를 업로드 해주세요.">{'이미지 업로드'}</Text>
                            <Uploader
                                className={css.uploader}
                                type="image"
                                data={attachedImg}
                                onComplete={handleSetImg}
                            />
                        </> :
                        <>
                            <Text desc="원하는 이미지 스타일을 선택해 주세요.">{'일러스트 스타일'}</Text>
                            <div className={css.stylebox}>
                                <StyleList
                                    data={styleList}
                                    selected={styleId}
                                    onChange={(selected: string) => dispatch(setStyleId(selected))}
                                />
                            </div>
                            <Text desc="동영상으로 만들기 원하는 사진을 업로드 해주세요.">{'사진 업로드'}</Text>
                            <Uploader
                                className={css.uploader}
                                type="image"
                                data={attachedPic}
                                onComplete={handleSetPic}
                            />
                        </>
                }

                <div className={css.buttons}>
                    <ProgressButton
                        className={css.createBtn}
                        progress={progress}
                        disabled={!enable}
                        onClick={handleCreate}
                    >
                        {creating ? '동영상 생성중...' : '동영상 생성'}
                    </ProgressButton>
                </div>
            </div>
            <div className={css.container}>
                {creating ?
                    <Standby /> :
                    dataInfo?.resourceId ?
                        <VideoViewer onRecreate={handleCreate} type={activeTab} /> :
                        <Standby />
                }
            </div>
        </div>
    );
}

export default AIVideoPanel;
