const isTouchScreen =
  typeof window !== 'undefined' && window.matchMedia('(hover: none) and (pointer: coarse)').matches;

let startX: number;
let startY: number;
let startRight: number;
let startBottom: number;

export default function registDragEvent(
    onDragChange: ({
        deltaX,
        deltaY,
        startX,
        startY,
        startRight,
        startBottom
    }: {
        deltaX: number,
        deltaY: number,
        startX: number,
        startY: number,
        startRight: number,
        startBottom: number
    }) => void,
    stopPropagation?: boolean,
) {
    return {
        onMouseDown: (clickEvent: React.MouseEvent<Element, MouseEvent>) => {
            startX = (clickEvent.target as any)?.offsetParent?.offsetLeft;
            startY = (clickEvent.target as any)?.offsetParent?.offsetTop;
            startRight = startX + (clickEvent.target as any)?.offsetParent?.offsetWidth;
            startBottom = startY + (clickEvent.target as any)?.offsetParent?.offsetHeight;
            if (stopPropagation) clickEvent.stopPropagation();

            const mouseMoveHandler = (moveEvent: MouseEvent) => {
                const deltaX = moveEvent.screenX - clickEvent.screenX;
                const deltaY = moveEvent.screenY - clickEvent.screenY;
                onDragChange({deltaX, deltaY, startX, startY, startBottom, startRight});
            };

            const mouseUpHandler = () => {
                document.removeEventListener('mousemove', mouseMoveHandler);
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler, { once: true });
        },
    };
}
