import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface userDataType {
    userId: string;
}

const initialState: userDataType = {
    userId: 'test1' // TODO: fix
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserId: (state: userDataType, action: PayloadAction<string>) => {
            state.userId = action.payload;
        },
        resetUserData: () => initialState
    },
    extraReducers: (builder) => {
        //
    }
});

export const {
    setUserId,
    resetUserData
} = userSlice.actions;

export default userSlice.reducer;
