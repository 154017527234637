import { format } from "date-fns";
import { RootState, store } from '../store';

export const inrange = (v: number, min: number, max: number) => {
    if (v < min) return min;
    if (v > max) return max;
    return v;
};

export const isEmptyPosition = ({width, height, x, y, xPercent, yPercent, flip}: {width: number, height: number, x: number, y: number, xPercent: number, yPercent: number, flip: boolean}) => {
    return typeof x !== 'number' || typeof y !== 'number' || typeof xPercent !== 'number' || typeof yPercent !== 'number' || flip === undefined;
};

export const isEmptyObject = (obj: {[key: string]: any}) => {
    const keys = Object.keys(obj);
    if (keys.length === 0) {
        return true;
    } else {
        keys.forEach(item => {
            if (obj[item] === undefined || obj[item] === null || obj[item] === '' || !obj[item]) {
                return true;
            }
        })
    }
    return false;
};

export const getBaseFontSize = () => {
    // 보통 <html> 요소의 font-size를 기준으로 합니다.
    const htmlElement = document.documentElement;
    const fontSize = window.getComputedStyle(htmlElement).fontSize;
    // '16px' 같은 문자열이 반환되므로, 'px'를 제거하고 숫자로 변환합니다.
    return parseFloat(fontSize);
};

export const convertPxToNewBase = (pxValue: number, defaultBaseFontSize = 16) => {
    const currentBaseFontSize = getBaseFontSize();
    return pxValue * (currentBaseFontSize / defaultBaseFontSize);
};

export const getTimeString = (date: string, dateFormat='yyyy.MM.dd HH:MM') => {
    const d = format(new Date(date), dateFormat);
    return d;
};

export const isDev = process.env.NODE_ENV === 'development';

export const formatTimeFromSec = (seconds?: number) => {
    if (typeof seconds === 'undefined') return '';
    const h = Math.floor(seconds / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 3600 % 60);

    const hDisplay = (h < 10 ? '0' : '') + h + ':';
    const mDisplay = (m < 10 ? '0' : '') + m + ':';
    const sDisplay = (s < 10 ? '0' : '') + s;
    return hDisplay + mDisplay + sDisplay;
};
