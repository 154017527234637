import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isDev } from '../../utils';
import { GET, POST } from '../../utils/api';
import { reporterType } from '../common/commonSlice';
import mock from '../../__mocks__/sample.json';

export interface aiArticleType {
    videoId: string;
    ttsId: string;
    resourceInfo: {
        thumbnailUrl: string;
        src: string;
        filename: string;
    },
    articleTime: number;
    articleText: string;
    aiSummary: {
        headline: string;
        summary: string;
    }
}

interface dataInfoType {
    resourceId: string;
    script: {
        order: number;
        summary: string;
        headline: string;
    }[];
    thumbnailUrl: string;
    videoUrl: string;
}

interface templateType {
    id: string;
    src: string;
    headlinePos: string;
    title: string;
    withAiHuman: boolean;
    withFootage: boolean;
}

export interface bgType {
    id: string;
    label: string;
    src: string;
}

interface bgmType {
    id: string;
    label: string;
    src: string;
}

export interface newsDataType {
    bTitle: string;
    bDate: string;
    bEpisode: string;
    aiArticle: aiArticleType[];
    validAiArticleCnt: number;
    templateList: templateType[];
    selectedBg: bgType;
    selectedBgm: string;
    selectedTemplate: templateType;
    selectedReporter: reporterType;
    dataInfo: dataInfoType;
    bgList: bgType[];
    bgmList: bgmType[];
    resourceId: string;
    withFootage: boolean;
    loading: boolean;
    error: boolean;
    playingBgm: string;
}

const defaultArticle: aiArticleType = {
    videoId: '',
    ttsId: '',
    resourceInfo: {
        thumbnailUrl: '',
        src: '',
        filename: ''
    },
    articleText: '',
    articleTime: 20,
    aiSummary: {
        headline: '',
        summary: ''
    }
};

const defaultDataInfo = {
    resourceId: '',
    script: [],
    thumbnailUrl: '',
    videoUrl: ''
};

export const initialState: newsDataType = {
    bTitle: '',
    bDate: '',
    bEpisode: '',
    selectedBg: {
        id: '',
        label: '',
        src: ''
    },
    selectedBgm: '',
    selectedTemplate: {
        id: '',
        src: '',
        headlinePos: '',
        title: '',
        withAiHuman: false,
        withFootage: false
    },
    selectedReporter: {
        broadcast: '',
        gender: '',
        id: '',
        name: '',
        speed: 1.0,
        src: '',
        profileUrl: '',
        fullProfileUrl: ''
    },
    withFootage: false,
    aiArticle: [
        {
            ...defaultArticle,
            articleText: isDev ? mock.articles[0] : ''
        },
        {
            ...defaultArticle,
            articleText: isDev ? mock.articles[1] : ''
        },
        {
            ...defaultArticle,
            articleText: isDev ? mock.articles[2] : ''
        }
    ],
    validAiArticleCnt: 0,
    bgList: [],
    bgmList: [],
    templateList: [],
    dataInfo: {...defaultDataInfo},
    resourceId: '',
    loading: false,
    playingBgm: '',
    error: false
};

export const createSummary = createAsyncThunk('/ai-news/create-summary', async ({
    index
}: {
    index: number;
}, {
    getState
}: any) => {
    const store = (getState() as any);
    const {userId} = store.user;
    const {aiArticle, selectedReporter} = store.news;
    const item = aiArticle[index];
    const params: {
        userId: string;
        articleText: string;
        articleTime?: number;
        reporterId: string;
        speed: number;
        videoId?: string;
    } = {
        userId,
        articleText: item.articleText,
        reporterId: selectedReporter.id,
        speed: selectedReporter.speed
    };

    if (item.videoId) {
        params.videoId = item.videoId;
    } else {
        params.articleTime = item.articleTime;
    }


    const res = await POST('ai-news/create-summary', params);
    if (!res.errMsg) {
        const temp = [...aiArticle].map((obj, idx) => {
            if (index === idx) {
                return {
                    ...obj,
                    ttsId: res.ttsId,
                    aiSummary: {
                        headline: res.headline,
                        summary: res.summary
                    }
                }
            } else {
                return obj;
            }
        });
        return temp;
    } else {
        //return aiArticle;
        throw new Error(res.errMsg);
    }
});

/*
{
  "article": [
    {
      "headline": "string",
      "order": 0,
      "text": "string",
      "ttsId": "string",
      "videoId": "string"
    }
  ],
  "bgId": "string",
  "bgmId": "string",
  "broadDate": "string",
  "broadEpisode": "string",
  "reporterId": "string",
  "speed": 0,
  "templateId": "string",
  "title": "string",
  "userId": "string"
}
*/

export const createNews = createAsyncThunk('/ai-news/create-ai-news', async (_, {getState}) => {
    const store = (getState() as any);
    const {userId} = store.user;
    const state = store.news;
    const params = {
        article: state.aiArticle.filter((item: aiArticleType) => !!item.ttsId).map((item: aiArticleType, index: number) => {
            return {
                order: index,
                headline: item.aiSummary.headline,
                text: item.aiSummary.summary,
                ttsId: item.ttsId,
                videoId: item.videoId
            }
        }),
        bgId: state.selectedBg.id || '',
        bgmId: state.selectedBgm,
        broadDate: state.bDate,
        broadEpisode: state.bEpisode,
        reporterId: state.selectedReporter.id,
        speed: state.selectedReporter.speed,
        templateId: state.selectedTemplate.id,
        title: state.bTitle,
        userId
    };
    const res = await POST('ai-news/create-ai-news', params);
    if (res.result === -1) {
        return '';
    } else {
        return res.resourceId;
    }
});

export const getBg = createAsyncThunk('/ai-news/get-bg', async (_, {getState}) => {
    const store = (getState() as any);
    const {userId} = store.user;
    const params = new URLSearchParams({
        userId
    });
    const res = await GET('ai-news/get-bg', params);
    if (!res.errMsg && Array.isArray(res.bgList) && res.bgList) {
        return res.bgList;
    } else {
        return [];
    }
});

export const getBgm = createAsyncThunk('/ai-news/get-bgm', async (_, {getState}) => {
    const store = (getState() as any);
    const {userId} = store.user;
    const params = new URLSearchParams({
        userId
    });
    const res = await GET('ai-news/get-bgm', params);
    if (!res.errMsg && Array.isArray(res.bgmList) && res.bgmList) {
        return res.bgmList;
    } else {
        return [];
    }
});

export const getStatus = async ({resourceId, userId}: {resourceId: string; userId: string;}) => {
    const params = new URLSearchParams({
        userId,
        resourceId
    });
    return await GET('ai-news/get-ai-news-status', params);
};

export const getTemplate = createAsyncThunk('/ai-news/get-template', async (_, {getState}) => {
    const store = (getState() as any);
    const {userId} = store.user;
    const params = new URLSearchParams({
        userId
    });
    const res = await GET('ai-news/get-template', params);
    if (!res.errMsg && Array.isArray(res.templateList) && res.templateList) {
        return res.templateList;
    } else {
        return [];
    }
});

const countValidSummaries = (data: aiArticleType[]) => {
    let validSummaryCount = 0;

    for (const article of data) {
        if (article.aiSummary && article.aiSummary.headline.trim() !== '') {
            validSummaryCount++;
        }
    }

    return validSummaryCount;
};

export const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setTitle: (state: newsDataType, action: PayloadAction<string>) => {
            state.bTitle = action.payload;
        },
        setDate: (state: newsDataType, action: PayloadAction<string>) => {
            state.bDate = action.payload;
        },
        setEpisode: (state: newsDataType, action: PayloadAction<string>) => {
            state.bEpisode = action.payload;
        },
        setBg: (state: newsDataType, action: PayloadAction<bgType>) => {
            state.selectedBg = action.payload;
        },
        setBgm: (state: newsDataType, action: PayloadAction<string>) => {
            state.selectedBgm = action.payload;
        },
        setPlayingBgm: (state: newsDataType, action: PayloadAction<string>) => {
            state.playingBgm = action.payload;
        },
        setTemplate: (state: newsDataType, action: PayloadAction<templateType>) => {
            state.selectedTemplate = action.payload;
        },
        setReporter: (state: newsDataType, action: PayloadAction<reporterType>) => {
            state.selectedReporter = action.payload;
        },
        setWithFootage: (state: newsDataType, action: PayloadAction<boolean>) => {
            state.withFootage = action.payload;
        },
        setAiArticle: (state: newsDataType, action: PayloadAction<aiArticleType[]>) => {
            state.aiArticle = action.payload;
            state.validAiArticleCnt = countValidSummaries(action.payload);
        },
        setDuration: (state: newsDataType, action: PayloadAction<{index: number, value: number}>) => {
            state.aiArticle[action.payload.index].articleTime = action.payload.value;
            state.aiArticle[action.payload.index].videoId = '';
        },
        setResource: (state: newsDataType, action: PayloadAction<{index: number, data: {resourceId: string, thumbnailUrl: string; src: string; filename: string;}}>) => {
            state.aiArticle[action.payload.index].videoId = action.payload.data.resourceId;
            state.aiArticle[action.payload.index].resourceInfo = {
                thumbnailUrl: action.payload.data.thumbnailUrl,
                src: action.payload.data.src,
                filename: action.payload.data.filename
            };
            state.aiArticle[action.payload.index].aiSummary = {
                headline: '',
                summary: ''
            };
        },
        resetSummary: (state: newsDataType, action: PayloadAction<{index: number}>) => {
            state.aiArticle[action.payload.index].aiSummary = {
                headline: '',
                summary: ''
            };
        },
        addSheet: (state: newsDataType) => {
            const data = [...state.aiArticle, {...defaultArticle}];
            state.aiArticle = data;
        },
        setStatus: (state: newsDataType, action: PayloadAction<dataInfoType>) => {
            state.dataInfo = action.payload;
            state.resourceId = '';
        },
        setError: (state: newsDataType, action: PayloadAction<boolean>) => {
            state.error = action.payload;
        },
        resetStatus: (state: newsDataType) => {
            state.dataInfo = {...defaultDataInfo};
        },
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(createNews.fulfilled, (state, action) => {
            state.loading = false;
            state.resourceId = action.payload;
        });
        builder.addCase(createNews.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createNews.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(createSummary.fulfilled, (state, action) => {
            state.loading = false;
            state.aiArticle = action.payload;
            state.validAiArticleCnt = countValidSummaries(action.payload);
        });
        builder.addCase(createSummary.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createSummary.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        builder.addCase(getBg.fulfilled, (state, action) => {
            state.bgList = action.payload;
        });

        builder.addCase(getBgm.fulfilled, (state, action) => {
            state.bgmList = action.payload;
        });

        builder.addCase(getTemplate.fulfilled, (state, action) => {
            state.templateList = action.payload;
        });
    }
});

export const {
    setTitle,
    setDate,
    setEpisode,
    setAiArticle,
    setBg,
    setBgm,
    setPlayingBgm,
    setTemplate,
    setReporter,
    setWithFootage,
    setDuration,
    setResource,
    resetSummary,
    addSheet,
    setStatus,
    resetStatus,
    reset,
    setError
} = newsSlice.actions;

export default newsSlice.reducer;
