import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import Button from '../Button/Button';
import Popup from '../Popup/Popup';
import css from './Tabs.module.scss';

interface OptionType {
    value: string;
    label: string;
}

const Tab = ({
    children,
    selected,
    onClick
}: {
    children: string;
    selected?: boolean;
    onClick: () => void;
}) => {
    return (
        <div
            className={classNames(css.tab, {[css.selected]: selected})}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

const Tabs = ({
    options = [],
    onSelect
}: {
    options: OptionType[];
    onSelect?: (selected: string) => void;
}) => {
    const [active, setActive] = useState<string>(options?.[0].value);
    const [pendingValue, setPendingValue] = useState<string>('');
    const [openPopup, setPopup] = useState<boolean>(false);

    const handleSelect = (value: string) => {
        if (value !== active) {
            setPendingValue(value);
            setPopup(true);
        }
    };

    const onConfirm = useCallback(() => {
        setPopup(false);
        setActive(pendingValue);
    }, [pendingValue]);

    useEffect(() => {
        onSelect && onSelect(active);
        setPendingValue('');
    }, [active]);

    return (
        <>
            <div className={css.tabs}>
                {options.map((item: OptionType) => {
                    return (
                        <Tab
                            key={item.value}
                            selected={active === item.value}
                            onClick={() => handleSelect(item.value)}
                        >
                            {item.label}
                        </Tab>
                    );
                })}
            </div>
            <Popup
                open={openPopup}
                content={'변경 시, 기존 내용은 모두 초기화됩니다.\n변경 하시겠습니까?'}
                onClose={() => setPopup(false)}
                buttons={
                    <>
                        <Button type="line" onClick={() => setPopup(false)}>{'취소'}</Button>
                        <Button onClick={onConfirm}>{'확인'}</Button>
                    </>
                }
            />
        </>
    );
};

export default Tabs;
