import Icon from '../../components/common/Icon/Icon';
import Popup from '../../components/common/Popup/Popup';
import VideoPlayer from '../AIVideo/VideoPlayer';
import css from './FullScreenPopup.module.scss'


const FullScreenPopup = ({
    type,
    open,
    data,
    onClose,
    handleDownload
}: {
    type: 'image' | 'video',
    open?: boolean;
    data?: any;
    onClose?: () => void;
    handleDownload?: () => void;
}) => {

    return (
        <Popup
            open={open}
            onClose={onClose}
            useCloseButton
        >
            <div className={css.popupContent}>
                <div className={css.viewer}>
                    {
                        type === 'image' ?
                            <img alt="" src={data?.thumbnail} /> :
                            <VideoPlayer
                                url={'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'}
                                loop
                                rightSlot={
                                    <Icon onClick={handleDownload}>{'download'}</Icon>
                                }
                            />
                    }
                </div>
                <div className={css.script}>
                    {`${data?.styleName}\n${data?.info}`}
                </div>
            </div>
        </Popup>
    );
};

export default FullScreenPopup;
