import axios from 'axios';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AudioWave from '../../components/common/AudioWave/AudioWave';
import Button from '../../components/common/Button/Button';
import TextField from '../../components/common/TextField/TextField';
import { downloadResource, downloadTts } from '../../features/common/commonSlice';
import { setArticle } from '../../features/human/humanSlice';
import { AppDispatch, RootState } from '../../store';
import css from './AudioEdit.module.scss'


const AudioEdit = ({
    type,
    onApply
}: {
    type?: string;
    onApply?: (text: string) => void;
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { ttsInfo, article, ttsId } = useSelector((state: RootState) => state.human);
    const { userId } = useSelector((state: RootState) => state.user);
    const [value, setValue] = useState<string>(article.text);
    const [audio, setAudio] = useState<string>('');

    const changeAudio = useCallback(() => {
        onApply && onApply(value);
    }, [onApply, value]);

    const handleDownload = useCallback(async () => {
        if (userId && ttsId) {
            const response = await downloadTts({userId, ttsId});
            if (!response.errMsg && response.url) {
                window.open(response.url, '_blank', 'noreferrer');
            }
        }
    }, [userId, ttsId]);

    useEffect(() => {
        if (type === 'video') return;
        dispatch(setArticle({
            text: value,
            textLength: value.length
        }));
    }, [dispatch, value, type]);

    useEffect(() => {
        if (ttsInfo.src) {
            setAudio(ttsInfo.src);
        }
    }, [ttsInfo]);

    return (
        <div className={css.viewer}>
            <div className={css.top}>
                {type === 'video' ?
                    <Button marked icon="check" disabled={value === article.text} onClick={changeAudio}>{'변경사항 적용하기'}</Button> :
                    <Button icon="download" marked onClick={handleDownload}>{'음성 파일 다운로드'}</Button>
                }
            </div>
            <div className={css.contents}>
                <div className={css.waveform}>
                    <AudioWave audioSrc={audio} />
                </div>
            </div>
            <div className={classNames(css.scripts, {[css.videoType]: type === 'video'})}>
                {type === 'video' ?
                    <li className={css.guide}>{'[space bar]로 단어 사이를 넓혀 쉬어가는 길이를 조절할 수 있습니다.'}</li> : null
                }
                <TextField
                    multiLine
                    className={css.scriptField}
                    onChange={(ev: any) => setValue(ev.target.value)}
                    value={value}
                />
            </div>
        </div>
    );
};

export default AudioEdit;
