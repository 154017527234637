import classNames from 'classnames';
import css from './ProgressButton.module.scss';

const ProgressButton = ({
    children,
    className,
    disabled,
    progress = null,
    onClick,
}: {
    children?: string;
    className?: string;
    disabled?: boolean;
    progress?: number | null;
    onClick?: (ev: any) => void;
}) => {
    const componentCss = classNames(css.progressButton, className, {
        [css.disabled]: disabled,
        [css.inProgress]: typeof progress === 'number' && progress >= 0
    });
    return (
        <div className={componentCss} onClick={onClick}>
            <div className={css.text}>{children}</div>
            {typeof progress === 'number' && progress >= 0 ? <div className={css.progress}>{`${progress}%`}</div> : null}
            {typeof progress === 'number' && progress >= 0?
                <div
                    className={css.progressBar}
                    style={{
                        left: `${Math.round(progress).toFixed(2)}%`
                    }}
                /> : null
            }
        </div>
    );
};

export default ProgressButton;
