import { useEffect, useRef, useState } from 'react';
import Dropdown from '../../components/common/Dropdown/Dropdown';
import ImageListItem from '../../components/common/ImageListItem/ImageListItem';
import css from './Template.module.scss'

import AudioWaveItem from '../../components/AINews/AudioWaveItem';
import BgItem from '../../components/AINews/BgItem';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { bgType, getBg, getBgm, getTemplate, setBg, setBgm, setTemplate } from '../../features/news/newsSlice';
import Image from '../../components/common/Image/Image';

const Template = ({
    withHuman
}: {
    withHuman?: boolean;
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const bgmUploadRef = useRef(null);
    const bgUploadRef = useRef(null);
    const { bgList, bgmList, templateList, selectedBg, selectedBgm, selectedTemplate, withFootage } = useSelector((state: RootState) => state.news);
    const [templates, setTemplates] = useState<any[]>([]);

    useEffect(() => {
        dispatch(getTemplate());
        dispatch(getBg());
        dispatch(getBgm());
    }, [dispatch]);

    useEffect(() => {
        if (templateList.length > 0) {
            //dispatch(setTemplate(templateList[0]));
            const list = templateList
                .filter(item => withHuman ? item.withAiHuman : !item.withAiHuman)
                .filter(item => withFootage === item.withFootage);
            setTemplates(list);
        }
    }, [dispatch, templateList, selectedTemplate.id, withFootage, withHuman]);

    useEffect(() => {
        if (templates.length > 0) {
            dispatch(setTemplate(templates[0]));
        }
    }, [dispatch, templates]);

    useEffect(() => {
        if (bgList.length > 0) {
            dispatch(setBg(bgList[0]));
        }
    }, [dispatch, bgList]);

    useEffect(() => {
        if (bgmList.length > 0) {
            dispatch(setBgm(bgmList[0].id));
        }
    }, [dispatch, bgmList]);

    const handleClickAudioTitle = () => {
        if (bgmUploadRef.current) {
            (bgmUploadRef.current as any).openFileInput();
        }
    };

    const handleClickImgTitle = () => {
        if (bgUploadRef.current) {
            (bgUploadRef.current as any).openFileInput();
        }
    };

    const handleSelectBg = (bg: bgType) => {
        dispatch(setBg(bg));
    };

    const handleSelectBgm = (id: string) => {
        dispatch(setBgm(id));
    };

    return (
        <div className={css.viewer}>
            <div className={css.top}>
                <Dropdown
                    placeholder="배경 이미지"
                    className={css.bgSelector}
                    menuClassName={css.bgMenu}
                    options={bgList}
                    selected={selectedBg.id}
                    icon="background"
                    menuComponent={
                        <div className={css.bgList}>
                            {bgList.map(item => {
                                return (
                                    <BgItem
                                        key={item.id}
                                        src={item.src}
                                        title={item.label}
                                        selected={item.id === selectedBg.id}
                                        onClick={() => handleSelectBg(item)}
                                        onClickTitle={handleClickImgTitle}
                                    />
                                )
                            })}
                        </div>
                    }
                />
                <Dropdown
                    placeholder="배경 음악"
                    className={css.bgSelector}
                    menuClassName={css.bgmMenu}
                    options={bgmList}
                    selected={selectedBgm}
                    icon="music"
                    menuComponent={
                        <div className={css.bgmList}>
                            {bgmList.map(item => {
                                return (
                                    <AudioWaveItem
                                        key={item.id}
                                        id={item.id}
                                        src={item.src}
                                        selected={item.id === selectedBgm}
                                        title={item.label}
                                        onApply={() => handleSelectBgm(item.id)}
                                        onClickTitle={handleClickAudioTitle}
                                    />
                                )
                            })}
                        </div>
                    }
                />
            </div>
            <div className={css.contents}>
                <div className={css.template}>
                    <div className={css.bg}>
                        <Image
                            src={selectedBg.src}
                        />
                    </div>
                    <Image
                        src={selectedTemplate.src}
                    />
                    {/*<VideoPlayer
                        url={selectedTemplate}  //https://www.youtube.com/watch?v=KNexS61fjus
                        loop
                        backgroundImage={selectedBg?.src}
                    />*/}
                </div>
            </div>
            <div className={css.bottom}>
                <div className={css.list}>
                    {
                        templates.map((item) => {
                            return (
                                <ImageListItem
                                    key={item.id}
                                    src={item.src}
                                    selected={item.id === selectedTemplate.id}
                                    onClick={() => dispatch(setTemplate(item))}
                                />
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default Template;
