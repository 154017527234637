import axios from 'axios'
import dayjs from 'dayjs';

axios.defaults.withCredentials = true;

let controllers: any[] = [];

export const API_URL = 'https://api-dev.ai-news-skb.net/'; // 'http://localhost:3030/'; // TODO:

export const separate_ext = (title: string) => {
    return /(.*?)(\.[^.]*$|$)/.exec(title) || '';
};

export const formatBytes = (b: number) => {
    const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let l = 0
    let n = b

    while (n >= 1024) {
        n /= 1024
        l += 1
    }

    return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)}${units[l]}`
};

export const getToken = async (): Promise<string> => {
    const refreshToken = localStorage.getItem('refreshToken');
    const expiredTime = sessionStorage.getItem('expiredTime');
    let token = sessionStorage.getItem('accessToken');

    // deep link 방식으로 접근 시 sessionStorage가 비어있음
    if (!token || !expiredTime) {
        if (refreshToken) {
            // 다시 /auth/refresh-token 호출
            // 토큰 갱신 서버통신
            const { data } = await axios.post(API_URL + '/auth/refresh-token', {refreshToken: refreshToken});
            if (data.error) {
                if (data.error.code === 'ERR_104' || data.error.code === 'ERR_105') {
                    sessionStorage.removeItem('accessToken');
                    sessionStorage.removeItem('expiredTime');
                    localStorage.removeItem('refreshToken');
                    if (window.location.pathname !== '/') {
                        window.location.href = "/";
                    }
                }
            } else {
                localStorage.setItem('lastExe', Date.now().toString());

                /* data.item.refreshToken 값은 null */
                token = data.item.accessToken;
                sessionStorage.setItem('accessToken', data.item.accessToken);
                sessionStorage.setItem('expiredTime', data.item.expiredTime);
            }
        }
        return token || '';
    }

    // 토큰이 만료되었고, refreshToken 이 저장되어 있을 때
    if (dayjs(+expiredTime).subtract(1, 'm').isBefore(dayjs()) && refreshToken) {
        // 토큰 갱신 서버통신
        const { data } = await axios.post(API_URL + '/auth/refresh-token', {refreshToken: refreshToken});
        if (data.error) {
            if (data.error.code === 'ERR_104' || data.error.code === 'ERR_105') {
                sessionStorage.removeItem('accessToken');
                sessionStorage.removeItem('expiredTime');
                localStorage.removeItem('refreshToken');
                if (window.location.pathname !== '/') {
                    window.location.href = "/";
                }
            }
        } else {
            localStorage.setItem('lastExe', Date.now().toString());

            /* data.item.refreshToken 값은 null */
            token = data.item.accessToken;
            sessionStorage.setItem('accessToken', data.item.accessToken);
            sessionStorage.setItem('expiredTime', data.item.expiredTime);
        }
    }

    return token || '';
};

export const POST = async (func: any, params: any) => {
    const controller = new AbortController();
    controllers.push(controller);

    try {
        const res = await axios.post(`${API_URL}${func}`, params, {
            signal: controller.signal
        });
        if (res !== null && typeof res === 'object') {
            const {status, data} = res;
            switch (status) {
            case 200:
                return data;
            case 504:
                console.log('Gateway timeout!!!');
                return {
                    errMsg: '[504] Gateway timeout error.'
                };
            }
        } else {
            console.warn('API POST ERROR')
        }
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('[Canceled API]:', func);
        } else {
            console.error('API POST ERROR:', error);
        }
    } finally {
        // 완료된 controller는 배열에서 제거
        controllers = controllers.filter(ctrl => ctrl !== controller);
    }
};

export const DELETE = async (func: any, params: any) => {
    const res = await axios.delete(`${API_URL}${func}`, {
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    });
    if (res !== null && typeof res === 'object') {
        const {status, data} = res;
        switch (status) {
        case 200:
            return data;
        }
    } else {
        console.warn('API DELETE ERROR')
    }
};

export const GET = async (url: any, params?: any, options?: any) => {
    const controller = new AbortController();
    controllers.push(controller);

    try {
        const res = await axios.get(
            `${API_URL}${url}`,
            {
                params,
                signal: controller.signal
            }
        );
        if (res !== null && typeof res === 'object') {
            const {status, data} = res;
            switch (status) {
            case 200:
                return data;
            default:
                return null;
            }
        } else {
            console.warn('API GET ERROR')
        }
    } catch (error: any) {
        if (axios.isCancel(error)) {
            console.log('[Canceled API]:', url);
        } else {
            console.error('API GET ERROR:', error);
        }
    } finally {
        // 완료된 controller는 배열에서 제거
        controllers = controllers.filter(ctrl => ctrl !== controller);
    }
};

export const abortAllRequests = () => {
    console.log('current controllers: ', controllers);
    controllers.forEach(controller => controller.abort());
    controllers = [];
};

export const DOWNLOAD = async (filUrl: string) => {
    try {
        const response = await axios.get(filUrl, {
            responseType: 'blob', // important
        });

        // Create a URL for the blob object
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        // Extract filename from content-disposition header if available
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'downloaded_file';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }

        link.setAttribute('download', fileName); // specify filename if necessary
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading the file', error);
    }
}
