import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import './App.scss';
import Dashboard from './views/Dashboard/Dashboard';
import Layout from './components/common/Layout/Layout';
import AIImagePanel from './views/AIImage/AIImagePanel';
import AIVideoPanel from './views/AIVideo/AIVideoPanel';
import AIHumanPanel from './views/AIHuman/AIHumanPanel';
import AINewsPanel from './views/AINews/AINewsPanel';
import Login from './views/Login/Login';
import { Amplify } from 'aws-amplify';
import { getCurrentUser } from 'aws-amplify/auth';
import config from './amplifyconfiguration.json';
import { useEffect } from 'react';
import NotFound from './views/common/NotFound';
import { useDispatch } from 'react-redux';
import { AppDispatch } from './store';
import { setUserId } from './features/user/userSlice';
Amplify.configure(config);

const App = () => {
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const { username, userId, signInDetails } = await getCurrentUser(); // 로그인 상태 확인
                console.log(username, userId, signInDetails);
                dispatch(setUserId(userId));
                // 로그인 되어있으면 "/" 경로로 유지
                //window.location.href = window.location.origin;
            } catch {
                console.log('Not logged in.');
                if (!window.location.pathname.includes('login')) {
                    window.location.href = `${window.location.origin}/login`;
                }
            }
        };

        checkAuthStatus();
    }, []);

    const router = createBrowserRouter([
        {
            element: <Layout />,
            children: [
                {
                    path: "/",
                    element: <Navigate to={'/dashboard'} replace />
                },
                {
                    path: "/dashboard",
                    element: <Dashboard />
                },
                {
                    path: "/ai-image",
                    element: <AIImagePanel />
                },
                {
                    path: "/ai-video",
                    element: <AIVideoPanel />
                },
                {
                    path: "/ai-human",
                    element: <AIHumanPanel />
                },
                {
                    path: "/ai-news",
                    element: <AINewsPanel />
                },
                {
                    path: "/login",
                    element: <Login />
                },
                {
                    path: "*",
                    element: <NotFound />
                }
            ],
            errorElement: <NotFound />
        }
    ]);

    return (
        <RouterProvider router={router} />
    );
};

export default App;
