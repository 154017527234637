import { formatTimeFromSec } from '../../../utils';
import Slider from '../Slider/Slider';
import css from './ProgressBar.module.scss'


const ProgressBar = ({
    currentTime,
    duration,
    min,
    max,
    step,
    percentValue,
    noHandle,
    noTimeInfo,
    trackStyle,
    railStyle,
    leftSlot,
    rightSlot,
    onChange
}: {
    currentTime?: number;
    duration?: number;
    min: number;
    max: number;
    step: number;
    percentValue: number;
    noHandle?: boolean;
    noTimeInfo?: boolean;
    trackStyle?: [object] | object;
    railStyle?: object;
    leftSlot?: JSX.Element;
    rightSlot?: JSX.Element;
    onChange?: (ev: number) => void;
}) => {
    return (
        <div className={css.progressBar}>
            <div className={css.slot}>
                {leftSlot}
                {!noTimeInfo && <div className={css.time}>{formatTimeFromSec(currentTime)}</div>}
            </div>
            <Slider
                className={css.slider}
                min={min}
                max={max}
                step={step}
                value={percentValue}
                readonly={noHandle}
                trackStyle={trackStyle}
                railStyle={railStyle}
                onChange={onChange}
            />
            <div className={css.slot}>
                {!noTimeInfo && <div className={css.time}>{formatTimeFromSec(duration)}</div>}
                {rightSlot}
            </div>
        </div>
    );
};

export default ProgressBar;
