import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GET, POST } from '../../utils/api';

interface dataInfoType {
    resourceId: string;
    thumbnailUrl: string;
    usedImageUrl: string;
    videoUrl: string;
}

interface attachedResourceType {
    resourceId: string;
    thumbnailUrl: string;
    src: string;
    filename: string;
}

interface dataType {
    bTitle: string;
    bDate: string;
    bEpisode: string;
    attachedImg: attachedResourceType;
    attachedPic: attachedResourceType;
    styleId: string;
    dataInfo: dataInfoType;
    resourceId: string;
}

export interface styleListType {
    id: string;
    label: string;
    src: string;
}
export interface videoDataType extends dataType{
    styleList: styleListType[];
}

type CreateVideoPayload = {
    type: string;
};

export const createVideo = createAsyncThunk('/ai-video/create-video', async (payload: CreateVideoPayload, {getState}) => {
    const state = (getState() as any).video;
    const params: {
        userId: string;
        title: string;
        broadEpisode: string;
        broadDate: string;
        styleId?: string;
        resourceId: string;
    } = {
        userId: 'test',
        title: state.bTitle,
        broadEpisode: state.bEpisode.toString(),
        broadDate: state.bDate,
        resourceId: payload.type === 'image' ? state.attachedImg.resourceId : state.attachedPic.resourceId
    };

    if (payload.type === 'picture' && state.styleId) {
        params.styleId = state.styleId;
    }

    const res = await POST('ai-video/create-video', params);
    if (!res.errMsg && res.resourceId) {
        return res.resourceId;
    } else {
        return '';
    }
});

export const saveVideo = async ({resourceId}: {resourceId: string}) => {
    const params = {
        userId: 'test',
        resourceId
    }
    const res = await POST('ai-video/save-video', params);
    return res;
};

export const getStyle = createAsyncThunk('/ai-video/get-style', async () => {
    const params = new URLSearchParams({
        userId: 'text'
    });
    const res = await GET('ai-video/get-style', params);
    if (!res.errMsg && Array.isArray(res.styleList) && res.styleList) {
        return res.styleList;
    } else {
        return [];
    }
});

export const getStatus = async ({resourceId}: {resourceId: string}) => {
    const params = new URLSearchParams({
        userId: 'test',
        resourceId
    });
    return await GET('ai-video/get-status', params);
};

const defaultResource = {
    resourceId: '',
    thumbnailUrl: '',
    src: '',
    filename: ''
};

export const initialState: videoDataType = {
    bTitle: '',
    bDate: new Date().toISOString(),
    bEpisode: '',
    attachedImg: {...defaultResource},
    attachedPic: {...defaultResource},
    styleId: '',
    dataInfo: {
        resourceId: '',
        thumbnailUrl: '',
        usedImageUrl: '',
        videoUrl: ''
    },
    styleList: [],
    resourceId: ''
};

export const videoSlice = createSlice({
    name: 'video',  // video creation
    initialState,
    reducers: {
        setTitle: (state: videoDataType, action: PayloadAction<string>) => {
            state.bTitle = action.payload;
        },
        setDate: (state: videoDataType, action: PayloadAction<string>) => {
            state.bDate = action.payload;
        },
        setEpisode: (state: videoDataType, action: PayloadAction<string>) => {
            state.bEpisode = action.payload;
        },
        setStyleId: (state: videoDataType, action: PayloadAction<string>) => {
            state.styleId = action.payload;
        },
        setAttachedImg: (state: videoDataType, action: PayloadAction<{resourceId: string, thumbnailUrl: string; src: string; filename: string;}>) => {
            state.attachedImg = action.payload;
        },
        setAttachedPic: (state: videoDataType, action: PayloadAction<{resourceId: string, thumbnailUrl: string; src: string; filename: string;}>) => {
            state.attachedPic = action.payload;
        },
        setStatus: (state: videoDataType, action: PayloadAction<dataInfoType>) => {
            state.dataInfo = action.payload;
            state.resourceId = '';
        },
        resetStatus: (state: videoDataType) => {
            state.dataInfo = {
                resourceId: '',
                thumbnailUrl: '',
                usedImageUrl: '',
                videoUrl: ''
            };
        },
        resetVideoData: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(createVideo.fulfilled, (state, action) => {
            state.resourceId = action.payload;
        });

        builder.addCase(getStyle.fulfilled, (state, action) => {
            state.styleList = action.payload;
        });
    }
});

export const {
    setTitle,
    setDate,
    setEpisode,
    setStyleId,
    setAttachedImg,
    setAttachedPic,
    setStatus,
    resetStatus,
    resetVideoData
} = videoSlice.actions;

export default videoSlice.reducer;
