import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../components/common/Button/Button';
import Image from '../../components/common/Image/Image';
import ImageListItem from '../../components/common/ImageListItem/ImageListItem';
import { downloadResource } from '../../features/common/commonSlice';
import { saveImage } from '../../features/image/imageSlice';
import { RootState } from '../../store';
import css from './ImageViewer.module.scss';

const ImageViewer = ({
    onRecreate
}: {
    onRecreate?: () => void;
}) => {
    const listRef = useRef(null);
    const { dataInfo } = useSelector((state: RootState) => state.image);
    const [image, setImage] = useState<{resourceId: string; url: string;}>();

    useEffect(() => {
        if (dataInfo.length > 0) {
            setImage(dataInfo[0]);
        }
    }, [dataInfo]);

    const handleSaveImage = () => {
        if (image?.resourceId) {
            saveImage({
                resourceId: image.resourceId
            });
        }
    };

    const handleDownload = useCallback(async () => {
        if (image?.resourceId) {
            const response = await downloadResource({
                resourceId: image.resourceId
            });
            if (!response.errMsg && response.url) {
                window.open(response.url, '_blank', 'noreferrer');
            }
        }
    }, [image]);

    return (
        <>
            <div className={css.viewer}>
                <div className={css.top}>
                    <Button priority="primary" icon="reset" onClick={onRecreate}>{'비슷한 이미지 다시 생성하기'}</Button>
                    <Button priority="primary" icon="video">{'동영상으로 만들기'}</Button>
                    <Button priority="primary" icon="library" onClick={handleSaveImage}>{'라이브러리 저장하기'}</Button>
                    <Button icon="download" marked onClick={handleDownload}>{'이미지 다운로드'}</Button>
                </div>
                <div className={css.contents}>
                    <div className={css.image}>
                        {image?.url &&
                        <Image src={image.url} />
                        }
                    </div>
                </div>
                <div className={css.bottom}>
                    <div ref={listRef} className={css.list}>
                        {dataInfo?.map((item) => {
                            return (
                                <ImageListItem
                                    key={item.resourceId}
                                    src={item.url}
                                    selected={item.resourceId === image?.resourceId}
                                    onClick={() => setImage(item)}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ImageViewer;
