import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBgm, setPlayingBgm } from '../../features/news/newsSlice';
import { AppDispatch, RootState } from '../../store';
import css from './AudioWaveItem.module.scss'


const WaveAni = ({
    playing
}: {
    playing?: boolean;
}) => {
    return (
        <div className={classNames(css.waveAni, {[css.playing]: playing})}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
}

const AudioWaveItem = ({
    id,
    src,
    selected,
    title,
    useTitleLink,
    onApply,
    onClickTitle
}: {
    id: string;
    src: string;
    selected?: boolean;
    title: string;
    useTitleLink?: boolean;
    onApply?: () => void;
    onClickTitle?: () => void;
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const ref = useRef(null);
    const { selectedBgm, playingBgm } = useSelector((state: RootState) => state.news);
    const [isPlaying, setPlaying] = useState(false);

    const play = () => {
        if (ref.current) {
            (ref.current as any).play();
            setPlaying(true);
            dispatch(setPlayingBgm(id));
        }
    };

    const pause = () => {
        if (ref.current) {
            (ref.current as any).pause();
            (ref.current as any).currentTime = 0;
            setPlaying(false);
        }
    };

    useEffect(() => {
        if (selectedBgm !== id) {
            pause();
        }
    }, [id, selectedBgm]);

    useEffect(() => {
        if (playingBgm !== id) {
            pause();
        }
    }, [playingBgm, id])

    return (
        <div className={classNames(css.audioWaveItem, {[css.selected]: selected, [css.titleLink]: useTitleLink})}>
            <div className={css.left}>
                {isPlaying ?
                    <div className={css.pauseIcon} onClick={pause}/> :
                    <div className={css.playIcon} onClick={play} />
                }
                <WaveAni playing={isPlaying} />
            </div>
            <div className={css.title} onClick={useTitleLink ? onClickTitle : undefined}>
                {title}
            </div>
            <div className={css.apply} onClick={onApply}>{selected ? '적용중' :'적용'}</div>
            <audio
                ref={ref}
                src={src}
                onPlay={(ev: any) => console.log('play', ev)}
                onPause={(ev: any) => console.log('pause', ev)}
                loop
            />
        </div>
    );
};

export default AudioWaveItem;
