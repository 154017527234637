import classNames from 'classnames';
import Icon from '../Icon/Icon';
import css from './Guide.module.scss'


const Guide = ({
    type,
    overlay
}: {
    type: 'image' | 'video',
    overlay?: boolean;
}) => {
    return (
        <div className={classNames(css.guide, {[css.overlay]: overlay})}>
            <Icon size="tiny">{type === 'image' ? 'addImage' : 'addVideo'}</Icon>
            <div className={css.desc}>
                {type === 'image' ?
                    '이미지를 ' :
                    <div><span>{'동영상(.mp4)'}</span>을 </div>
                }
                {'드래그해서 올려놓거나\n'}
                <span>{'파일을 추가'}</span>
                {'해서 올려주세요.'}
            </div>
        </div>
    );
};

export default Guide;
