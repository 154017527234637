import { useEffect, useState } from 'react';
import StyleItem from '../../components/AiImage/StyleItem';
import { styleListType } from '../../features/image/imageSlice';
import css from './StyleList.module.scss';

const StyleList = ({
    data,
    selected,
    onChange
}: {
    data: styleListType[];
    selected?: string;
    onChange: (selected: string) => void;
}) => {
    const [items, setItems] = useState<styleListType[]>([]);
    const [showAll, setShowAll] =useState<boolean>(false);

    useEffect(() => {
        if (!showAll) { // 6개 보기
            if (data.length > 5) {
                setItems([...data.slice(0, 5), {id: 'showAll', src: '', label: ''}]);
            } else {
                setItems([...data, {id: 'ready', label: '', src: ''}]);
            }
        } else {    // 전체 보기
            setItems([...data, {id: 'ready', label: '', src: ''}]);
        }
    }, [data, showAll]);

    const clickItem = (id: string) => {
        onChange(id);
    };

    return (
        <div className={css.styleList}>
            {
                items?.map((item, index) => {
                    if (item.id === 'showAll') {
                        return (
                            <StyleItem
                                key={item.id}
                                type="showAll"
                                imageUrl={''}
                                label={''}
                                onClick={() => setShowAll(true)}
                            />
                        );
                    } else if (item.id === 'ready') {
                        return (
                            <StyleItem
                                key={item.id}
                                type="ready"
                                imageUrl={''}
                                label={''}
                            />
                        );
                    }
                    return (
                        <StyleItem
                            key={item.id}
                            imageUrl={item.src}
                            label={item.label}
                            onClick={() => clickItem(item.id)}
                            selected={item.id === selected}
                        />
                    );
                })
            }
        </div>
    );
};

export default StyleList;
