import classNames from 'classnames';
import Image from '../common/Image/Image';
import css from './BgItem.module.scss'


const BgItem = ({
    src,
    title = '',
    selected,
    useTitleLink,
    onClick,
    onClickTitle
}: {
    src: string;
    title: string;
    selected?: boolean;
    useTitleLink?: boolean;
    onClick?: () => void;
    onClickTitle?: () => void;
}) => {
    return (
        <div className={classNames(css.bgItem, {[css.selected]: selected, [css.titleLink]: useTitleLink})} onClick={onClick}>
            <div className={css.image}>
                <Image src={src} />
            </div>
            <div className={css.title} onClick={useTitleLink ? onClickTitle : undefined}>{title}</div>
        </div>
    );
};

export default BgItem;
