import { dataType } from '../../features/dashboard/dashboardSlice';
import CategoryOverlay from '../common/CategoryOverlay/CategoryOverlay';
import Icon from '../common/Icon/Icon';
import Image from '../common/Image/Image';
import Tooltip from '../common/Tooltip/Tooltip';
import css from './GridItem.module.scss';

const GridItem = ({
    data,
    onFullScreen,
    onDownload
}: {
    data: dataType;
    onFullScreen?: () => void;
    onDownload?: () => void;
}) => {
    return (
        <div
            className={css.gridItem}
        >
            <div className={css.bg}>
                {(data.thumbnail || data.srcUrl) && <Image src={data.type === 'image' ? data.srcUrl : data.thumbnail} />}
            </div>
            {data.type &&
                <CategoryOverlay
                    className={css.category}
                    color={data.type === 'image' ? 'green' : data.type === 'video' ? 'yellow' : undefined}
                >
                    {data.type === 'image' ? 'AI 이미지' : data.type === 'video' ? 'AI 비디오' : ''}
                </CategoryOverlay>
            }
            <Tooltip
                content={<span>{data.info}</span>}
            >
                <div className={css.info}>
                    <Icon size="mini">{'infoDark'}</Icon>
                </div>
            </Tooltip>
            <div className={css.icons}>
                <Icon className={css.icon} onClick={onFullScreen}>{'fullscreen'}</Icon>
                {data.enableDownload && <Icon className={css.icon} onClick={onDownload}>{'download'}</Icon>}
            </div>
        </div>
    );
};

export default GridItem;
