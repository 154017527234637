import { useSelector } from 'react-redux';
import GridItem from '../../components/Dashboard/GridItem';
import { RootState } from '../../store';
import css from './GridView.module.scss'
import { useCallback, useState } from 'react';
import FullScreenPopup from './FullScreenPopup';
import { downloadResource } from '../../features/common/commonSlice';
import { DOWNLOAD } from '../../utils/api';
import DownloadPopup from '../../components/Dashboard/DownloadPopup';
import NoData from './NoData';

const GridView = () => {
    const [openDownloadPopup, setDownloadPopup] = useState<boolean>(false);
    const [selectedItem, setSelected] = useState<any>(null);
    const [downloadId, setDownloadId] = useState<string>();
    const { data } = useSelector((state: RootState) => state.dashboard);

    const handleFullScreen = (item: any) => {
        setSelected(item);
    };

    const handleDownload = useCallback(() => {
        if (downloadId) {
            downloadResource({
                resourceId: downloadId
            }).then(res => {
                console.log(res);
                DOWNLOAD(res);
            });
            setDownloadId('');
        }
    }, [downloadId]);

    const handleOpenPopup = (id: string) => {
        setDownloadId(id);
        setDownloadPopup(true);
    };

    const handleClosePopup = () => {
        setDownloadId('');
        setDownloadPopup(false)
    };

    return (
        <>
            <div className={css.list}>
                {data && data.length > 0 ?
                    <div className={css.gridView}>
                        {data.map(item => {
                            return (
                                <GridItem
                                    key={item.id}
                                    data={item}
                                    onFullScreen={() => handleFullScreen(item)}
                                    onDownload={() => handleOpenPopup(item.id)}
                                />
                            );
                        })}
                    </div> :
                    <NoData />
                }
            </div>
            <FullScreenPopup
                type="image"
                open={!!selectedItem}
                onClose={() => setSelected(null)}
                data={selectedItem}
            />
            <DownloadPopup
                open={openDownloadPopup}
                onClose={handleClosePopup}
                onConfirm={handleDownload}
            />
        </>
    );
};

export default GridView;
